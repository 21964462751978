import * as React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';

import { initAnalytics } from 'kumba-common/src/libs/analytics';
import KumbaApps from 'kumba-common/src/enums/kumba-apps';
initAnalytics({ kumbaApp: KumbaApps.KUMBA_VETS });

import { initPipeDriveEventListener } from 'kumba-common/src/libs/pipedrive-event-listener';
initPipeDriveEventListener();

import { configure } from './config/aws';
configure();
import config from 'kumba-common/src/libs/logrocket';
config(KumbaApps.KUMBA_VETS);

import configureStore, { history } from './store/configure-store';
import App from './App';

import './styles/main.scss';
import { initClient } from 'kumba-common/src/libs/apollo';

import './register-service-worker';

// Release for sentry and other integrations
window.KUMBA_VERSION = 'kumba-vets@0.0.1';
const apolloClient = initClient();
const app = (
  <ApolloProvider client={apolloClient}>
    <ApolloHooksProvider client={apolloClient}>
      <Provider store={configureStore}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ApolloHooksProvider>
  </ApolloProvider>
);

render(app, document.getElementById('app'));
