import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import KumbaApps from 'kumba-common/src/enums/kumba-apps';

import langConfig, {
  getText,
  getTextDirection,
} from 'kumba-common/src/libs/i18n';
// Make sure this stays here so texts are loaded before
langConfig(KumbaApps.KUMBA_VETS);

import VetRoutes from './VetRoutes';
import AppBar from 'kumba-components/src/components/AppBar';

import { authInit, authLogout } from './actions/auth-actions';

import Toast, { ToastProps } from 'kumba-components/src/components/Toast';
import { PROFILE, SLOTS } from './constants/routes';
import AppStyledComponent from './styled-component';
import KumbaTheme from 'kumba-common/src/hoc/kumba-theme';
import Spinner from 'kumba-components/src/components/Spinner';
import { getTheme } from './services/theme';
import { appInit } from './actions/app-actions';
import AlertBox from 'kumba-components/src/components/AlertBox';
import menuItems from './config/menu-items';

interface AppProps {
  authInit: any;
  appInit: any;
  authLogout: any;
  isLoggedIn: boolean;
  location: any;
  history: any;
  id: string;
  actualUserId: string;
  isSpinnerShown: boolean;
  toasts: ToastProps[];
}

interface AppState {
  activeRoute: string;
  isLoggedIn: boolean | null;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: null,
      activeRoute: '',
    };
  }

  componentDidMount = () => {
    this.props.appInit();
    this.props.authInit();
  };

  componentDidUpdate = () => {
    const newRoute = this.props.location.pathname.replace('/', '');
    if (this.state.activeRoute !== newRoute) {
      this.setState({
        activeRoute: newRoute,
      });
    }
  };

  handleRouteChange = uid => {
    this.setState({
      activeRoute: uid,
    });
    this.props.history.push(`/${uid}`);
  };

  isActingUser = () =>
    this.props.actualUserId &&
    this.props.id &&
    this.props.id !== this.props.actualUserId;

  render() {
    const childProps = {
      isLoggedIn: this.props.isLoggedIn,
      id: this.props.id,
    };
    const direction = getTextDirection(KumbaApps.KUMBA_VETS);

    return (
      <AppStyledComponent direction={direction}>
        <KumbaTheme theme={getTheme({ direction })}>
          <AppBar
            activeRoute={this.state.activeRoute}
            onLogout={this.props.authLogout}
            isLoggedIn={this.props.isLoggedIn}
            logoutText={getText('common.buttons.logout')}
            onRouteChanged={this.handleRouteChange}
            menuItems={this.props.isLoggedIn ? menuItems : []}
            loginText={getText('common.buttons.login')}
            signupText={getText('signup')}
            onBackButtonClicked={this.props.history.goBack}
          />
          <div className="content">
            {this.isActingUser() && (
              <AlertBox message={`Acting as user id ${this.props.id}`} />
            )}
            {this.props.isSpinnerShown && <Spinner />}
            {!this.props.isSpinnerShown && (
              <VetRoutes childProps={childProps} />
            )}
          </div>
          {this.props.toasts.map((toast, index) => (
            <Toast key={index} {...toast} />
          ))}
        </KumbaTheme>
      </AppStyledComponent>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    toasts: state.app.toasts,
    isSpinnerShown: state.app.isSpinnerShown,
    id: state.auth.userId,
    actualUserId: state.auth.actualUserId,
  };
};
export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    { authInit, authLogout, appInit }
  )
)(App);
