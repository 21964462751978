import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getText } from 'kumba-common/src/libs/i18n';
import { getInventoryProducts } from '../../../graphql-custom/queries';
import Modal from 'kumba-components/src/components/Modal';
import Spinner from 'kumba-components/src/components/Spinner';
import Input from 'kumba-components/src/components/Input';
import { InputTypes } from 'kumba-components/src/components/Form';
import { getProductTextByTitleId } from '../helpers';
import { ButtonAdd } from 'kumba-components/src/components/Button';
import { selectedProductsReducer, SupplyActionTypes } from './helpers';
import ButtonDelete from 'kumba-components/src/components/Button/ButtonDelete';
import { supplyCreate } from '../../../actions/inventory-actions';
import initialState from '../../../reducers/initial-state';
import SCREEN_SIZES from 'kumba-utils/src/styles/screen-sizes.constant';

interface AddSupplyModalProps {
  onClose: () => void;
  supplyCreate: any;
  vetId: string;
}

interface InventoryProduct {
  id: string;
  titleId: string;
  price: number;
}

const AddSupplyModalStyledComponent = styled.div`
  @media ${SCREEN_SIZES.DESKTOP} {
    min-width: 570px;
  }
  max-width: 100vw;
  .product-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    .input-select {
      min-width: 50%;
    }
    button {
      border: none;
    }
  }
`;

function getNonSelectedProducts({
  allProducts,
  selectedProducts,
  currProductId,
}): InventoryProduct[] {
  const selectedProductIds = selectedProducts.map(p => p.id);
  return allProducts.filter(
    p => p.id === currProductId || !selectedProductIds.includes(p.id)
  );
}

function AddSupplyModal({ onClose, supplyCreate, vetId }: AddSupplyModalProps) {
  const { data, error, loading } = useQuery(gql(getInventoryProducts));
  const [selectedProducts, dispatch] = React.useReducer(
    selectedProductsReducer,
    [{ id: '', quantity: 0 }]
  );
  if (loading) {
    return <Spinner />;
  } else if (error || !data.products) {
    console.error(`error`, error);
    return <div>Something went wrong...</div>;
  } else {
    return (
      <Modal
        isOpen={true}
        title={getText('inventory.supply.title')}
        onClose={onClose}
        okLabel={getText('inventory.supply.add')}
        onOk={() => {
          supplyCreate({ selectedProducts, vetId });
          setTimeout(() => {
            onClose();
          }, 2000);
        }}
        isOkDisabled={!!selectedProducts.find(p => !p.id)}
      >
        <AddSupplyModalStyledComponent className="kumba-add-supply-modal">
          {selectedProducts.map((currSelectedProduct, index) => {
            const nonSelectedProducts = getNonSelectedProducts({
              selectedProducts,
              allProducts: data.products,
              currProductId: currSelectedProduct.id,
            });
            const productOptions = nonSelectedProducts.map(product => ({
              label: getProductTextByTitleId(product.titleId),
              value: product.id,
            }));
            const isRowDisabled = index !== selectedProducts.length - 1;
            return (
              <div key={currSelectedProduct.id} className="product-row">
                <Input
                  inputType={InputTypes.Select}
                  options={productOptions}
                  onChange={(e, val) =>
                    dispatch({
                      type: SupplyActionTypes.UPDATE_PRODUCT_ID,
                      productId: val,
                      oldProductId: currSelectedProduct.id,
                    })
                  }
                  isDisabled={isRowDisabled}
                  name="product"
                  value={currSelectedProduct.id}
                  isRequired={true}
                  label={getText('inventory.supply.product')}
                />
                <Input
                  inputType={InputTypes.Number}
                  isDisabled={isRowDisabled}
                  name="quantity"
                  value={currSelectedProduct.quantity}
                  isRequired={true}
                  onChange={(e, val) =>
                    dispatch({
                      type: SupplyActionTypes.UPDATE_QUANTITY,
                      productId: currSelectedProduct.id,
                      quantity: val,
                    })
                  }
                  label={getText('inventory.supply.quantity')}
                  minValue={0}
                />
                <ButtonDelete
                  onClick={() =>
                    dispatch({
                      type: SupplyActionTypes.DELETE_PRODUCT,
                      productId: currSelectedProduct.id,
                    })
                  }
                />
              </div>
            );
          })}
          <ButtonAdd
            isDisabled={!selectedProducts[selectedProducts.length - 1].id}
            text={getText('inventory.supply.addProduct')}
            onClick={() => dispatch({ type: SupplyActionTypes.ADD_PRODUCT })}
          />
        </AddSupplyModalStyledComponent>
      </Modal>
    );
  }
}

const mapStateToProps = (state = initialState) => {
  return {
    vetId: state.auth.userId,
  };
};
export default connect(
  mapStateToProps,
  { supplyCreate }
)(AddSupplyModal);
