import { takeEvery, put } from 'redux-saga/effects';

import { getText } from 'kumba-common/src/libs/i18n';
import {
  getCurrentIsoDate,
  getReadableStringTime,
  addUnitsToNow,
} from 'kumba-utils/src/date';
import { mutate } from 'kumba-common/src/libs/apollo';
import {
  startAppointment,
  appointmentEditTrigger,
  appointmentEndTrigger,
  startAppointmentNoShow,
} from '../../graphql-custom/mutations';
import { ROUTE } from '../../constants/routes';
import { push } from 'connected-react-router';

import { showMessage } from '../../actions/app-actions';
import { handleError, genericSagaHandler } from '../helpers';
import {
  APPOINTMENT_START,
  APPOINTMENT_EDIT,
  APPOINTMENT_END,
  APPOINTMENT_NO_SHOW_START,
} from '../../constants/action-types';
import { prettyPrint } from 'kumba-utils/src/string';
import { setData, deleteData } from 'kumba-utils/src/storage';
import { NO_SHOW_START_DATE } from 'kumba-common/src/constants/local-storage-items';
import { sendSms } from 'kumba-common/src/libs/sms';
import TimeUnits from 'kumba-utils/src/enums/time-units';
import { getActingUserUrl } from 'kumba-common/src/services/url';
import KumbaApps from 'kumba-common/src/enums/kumba-apps';

const KUMBA_PHONE_NUMBER = '+972546556776';

function* appointmentStartHandler(action) {
  const { appointmentId, petOwnerPhone, petOwnerId } = action;
  try {
    yield mutate({
      mutation: startAppointment,
      variables: {
        appointmentId,
        actualStartDate: getCurrentIsoDate(),
      },
    });
    const message = getText('appointment.start.success');

    yield put(showMessage({ message }));
  } catch (e) {
    yield handleError(e);
  }
}
function* appointmentNoShowStart(action) {
  const { appointmentId, petOwnerPhone, petOwnerId } = action;
  try {
    yield mutate({
      mutation: startAppointmentNoShow,
      variables: {
        appointmentId,
      },
    });

    setData(NO_SHOW_START_DATE, Date.now());
    const noShowEndTime = getReadableStringTime({
      stringDate: addUnitsToNow({ unit: TimeUnits.MINUTES, amount: 10 }),
    });
    // Send no show sms to admin and user
    yield sendSms({
      to: petOwnerPhone,
      text: getText('appointment.noShow.startUserSms', {
        endTime: noShowEndTime,
      }),
    });
    yield sendSms({
      to: KUMBA_PHONE_NUMBER,
      text: getText('appointment.noShow.startAdminSms', {
        appointmentId,
        petOwnerPhone,
        endTime: noShowEndTime,
        actingUserId: getActingUserUrl({
          appName: KumbaApps.KUMBA_PETS,
          preventEncoding: true,
          userId: petOwnerId,
        }),
      }),
    });

    yield put(
      showMessage({ message: getText('appointment.noShow.startSuccess') })
    );
  } catch (e) {
    yield handleError(e);
  }
}

function* appointmentEditHandler(action) {
  const { appointmentId, slotId, adminComment } = action;

  try {
    console.log(
      `Calling appointmentEditTrigger with the following params:${prettyPrint(
        action
      )}`
    );
    yield mutate({
      mutation: appointmentEditTrigger,
      variables: {
        data: {
          appointmentId,
          adminComment,
        },
      },
    });

    yield put(push(`/${ROUTE.replace(':slotId', slotId)}`));
    const message = getText('appointment.edit.success');
    yield put(showMessage({ message }));
  } catch (e) {
    yield put(push(`/${ROUTE.replace(':slotId', slotId)}`));
    yield handleError(e);
  }
}

function* appointmentEndHandler(action) {
  const {
    appointmentId,
    chipNumber,
    idNumber,
    weight,
    vetComment,
    slotId,
    petId,
    petOwnerId,
    selectedProducts = [],
    visitPetProduct,
    productsInSubscription = [],
    isNoShow,
    isRabiesGiven,
  } = action;

  try {
    console.log(
      `Calling appointmentEndTrigger with the following params:${prettyPrint(
        action
      )}`
    );
    deleteData(NO_SHOW_START_DATE);
    yield mutate({
      mutation: appointmentEndTrigger,
      variables: {
        data: {
          appointmentId,
          petOwnerId,
          productsInSubscription,
          selectedProducts,
          vetComment,
          chipNumber,
          weight,
          idNumber,
          isNoShow,
          appointmentProduct: visitPetProduct,
          isRabiesGiven,
          petId: isNoShow ? 'MOCK_PET_ID' : petId,
        },
      },
    });

    yield put(push(`/${ROUTE.replace(':slotId', slotId)}`));
    const message = getText('appointment.end.success');
    yield put(showMessage({ message }));
  } catch (e) {
    yield put(push(`/${ROUTE.replace(':slotId', slotId)}`));
    yield handleError(e);
  }
}

function* routeSaga() {
  yield takeEvery(
    APPOINTMENT_START,
    genericSagaHandler(appointmentStartHandler)
  );
  yield takeEvery(APPOINTMENT_EDIT, genericSagaHandler(appointmentEditHandler));
  yield takeEvery(APPOINTMENT_END, genericSagaHandler(appointmentEndHandler));
  yield takeEvery(
    APPOINTMENT_NO_SHOW_START,
    genericSagaHandler(appointmentNoShowStart)
  );
}

export default routeSaga;
