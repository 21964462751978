import * as React from 'react';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Button, { ButtonTypes } from 'kumba-components/src/components/Button';
import { getText } from 'kumba-common/src/libs/i18n';
import Confirm from 'kumba-components/src/components/Confirm';
import {
  NoShowActionTypes,
  noShowReducer,
  getRemainingTimerMinutes,
} from './helpers';
import { appointmentNoShowStart } from '../../../actions/appointment-actions';
import Timer from 'kumba-components/src/components/Timer';
import { getData } from 'kumba-utils/src/storage';
import { NO_SHOW_START_DATE } from 'kumba-common/src/constants/local-storage-items';

const NoShowStyledComponent = styled.div``;

interface NoShowProps {
  onStartAppointment: () => void;
  appointmentId: string;
  appointmentNoShowStart: any;
  appointmentNoShowEnd: any;
  petOwnerPhone: string;
  petOwnerId: string;
  onNoShowTimerEnded: () => void;
}

function NoShow({
  onStartAppointment,
  appointmentId,
  appointmentNoShowStart,
  petOwnerPhone,
  petOwnerId,
  onNoShowTimerEnded,
}: NoShowProps) {
  const [state, dispatch] = React.useReducer(noShowReducer, {
    noShowStartDate: null,
    isConfirmShown: false,
  });
  React.useEffect(() => {
    const storageNoShowStartDate = getData(NO_SHOW_START_DATE)
      ? Number(getData(NO_SHOW_START_DATE))
      : null;
    if (storageNoShowStartDate) {
      console.log(
        `Found no show start date in storage ${storageNoShowStartDate}`
      );
      dispatch({
        type: NoShowActionTypes.NO_SHOW_START,
        noShowStartDate: storageNoShowStartDate,
      });
    }
  }, []);
  const isNoShowStarted = !!state.noShowStartDate;

  const remainingMinutes = getRemainingTimerMinutes({
    noShowStartDate: state.noShowStartDate,
  });

  return (
    <NoShowStyledComponent>
      {
        <React.Fragment>
          <Button
            variant="contained"
            buttonType={ButtonTypes.Primary}
            onClick={onStartAppointment}
          >
            {getText('appointment.start')}
          </Button>
          {!isNoShowStarted && (
            <Button
              onClick={() => dispatch({ type: NoShowActionTypes.SHOW_CONFIRM })}
            >
              <PhonelinkEraseIcon />
              &nbsp;
              {getText('appointment.noShow.trigger')}
            </Button>
          )}
          <Confirm
            onClose={() => dispatch({ type: NoShowActionTypes.HIDE_CONFIRM })}
            onConfirm={() => {
              // First one is for global side effects - saga
              appointmentNoShowStart({
                appointmentId,
                petOwnerPhone,
                petOwnerId,
              });
              dispatch({
                type: NoShowActionTypes.NO_SHOW_START,
                noShowStartDate: Date.now(),
              });
            }}
            title={getText('appointment.noShow.trigger')}
            content={
              <React.Fragment>
                <span>Clicking ok will start a no-show process:</span>
                <ul>
                  <li>- 10 minute timer will start</li>
                  <li>
                    - SMS message will be sent to the user he has 10 minutes to
                    respond
                  </li>
                  <li>
                    - After the 10 minutes have passed, you may continue to the
                    next visit
                  </li>
                </ul>
                <br />
                <div>Are you sure you want to continue?</div>
              </React.Fragment>
            }
            isOpen={!!state.isConfirmShown}
          />
        </React.Fragment>
      }
      {!!remainingMinutes && (
        <Timer
          initialTimeMinutes={remainingMinutes}
          title={getText('appointment.noShow.timerTitle')}
          onTimerEnded={onNoShowTimerEnded}
        />
      )}
    </NoShowStyledComponent>
  );
}

export default connect(
  null,
  { appointmentNoShowStart }
)(NoShow);
