import * as React from 'react';

import SignupStyledComponent from './styled-component';
import Form, { InputTypes } from 'kumba-components/src/components/Form';
import { connect } from 'react-redux';
import { authSignup } from '../../actions/auth-actions';
import { getText } from 'kumba-common/src/libs/i18n';
import getDefaultErrorMessages from 'kumba-common/src/constants/error-messages';
import { LOGIN, SLOTS } from '../../constants/routes';
import { Link } from 'react-router-dom';

interface SignupProps {
  history: any;
  authSignup: any;
  email: string;
  password: string;
  userId: string;
  isLoggedIn: boolean;
}

class Signup extends React.Component<SignupProps> {
  signupInputDefinitions = [
    {
      inputType: InputTypes.Email,
      label: getText('signup.form.email.label'),
      name: 'email',
      isRequired: true,
    },
    {
      inputType: InputTypes.Password,
      label: getText('signup.form.password.label'),
      name: 'password',
      isRequired: true,
      initialValue: '',
    },
  ];

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push(SLOTS);
    }
  }

  render() {
    return (
      <SignupStyledComponent>
        <Form
          formTitle={getText('signup.title')}
          formSubtitle={
            <div>
              {getText('signup.subtitle')}&nbsp;
              <Link to={`/${LOGIN}`}>{getText('login')}</Link>
            </div>
          }
          inputDefinitions={this.signupInputDefinitions}
          onSubmit={this.props.authSignup}
          submitLabel={getText('signup')}
          errorMessages={getDefaultErrorMessages()}
        />
      </SignupStyledComponent>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.auth };
}

export default connect(
  mapStateToProps,
  { authSignup }
)(Signup);
