import {
  APPOINTMENT_START,
  APPOINTMENT_EDIT,
  APPOINTMENT_END,
  APPOINTMENT_NO_SHOW_START,
} from '../constants/action-types';

export function appointmentStart({ appointmentId }) {
  return {
    appointmentId,

    type: APPOINTMENT_START,
  };
}

export function appointmentNoShowStart({
  appointmentId,
  petOwnerPhone,
  petOwnerId,
}) {
  return {
    appointmentId,
    petOwnerPhone,
    petOwnerId,
    type: APPOINTMENT_NO_SHOW_START,
  };
}

export function appointmentEdit({ appointment, slotId, adminComment }) {
  return {
    adminComment,
    appointmentId: appointment.id,
    slotId,
    type: APPOINTMENT_EDIT,
  };
}

export function appointmentEnd({
  appointment,
  slotId,
  vetComment,
  chipNumber,
  idNumber,
  weight,
  visitPetProduct,
  selectedProductIdsInSubscription = [],
  selectedProducts = [],
  petOwnerId,
  isNoShow = false,
  isRabiesGiven = false,
}) {
  const productsInSubscription = (
    appointment.pet.petServices[0].petProducts || []
  )
    .filter(product =>
      (selectedProductIdsInSubscription as any).includes(product.id)
    )
    .map(product => {
      return {
        id: product.id,
        remainingQuantity: product.remainingQuantity,
      };
    });

  return {
    chipNumber,
    idNumber,
    slotId,
    vetComment,
    productsInSubscription,
    selectedProducts: (selectedProducts as any).map(sp => ({
      ...sp,
      quantity: Number(sp.quantity),
    })),
    petOwnerId,
    visitPetProduct,
    weight: Number(weight),
    appointmentId: appointment.id,
    petId: appointment.pet.id,
    isNoShow,
    isRabiesGiven,
    type: APPOINTMENT_END,
  };
}
