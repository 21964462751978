import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth-reducer';
import appReducer from './app-reducer';
import vetReducer from './vet-reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
    vet: vetReducer,
  });
