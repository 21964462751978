export default {
  vetId: '123',
  license: null,
  tShirtSize: null,
  user: {
    firstName: null,
    lastName: null,
    profilePictureUrl: '',
  },
};
