import Amplify, { Storage } from 'aws-amplify';
import awsDefaultConfig from '../aws-exports';

const dev = {
  s3: {
    REGION: 'eu-west-1',
    BUCKET: 'kumba-serverless-api-dev-attachmentsbucket-hvp5goavvdif',
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://clteimygy1.execute-api.eu-west-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_DNywArROV',
    APP_CLIENT_ID: '673rcsuqju8lljvph2djd8sof3',
    IDENTITY_POOL_ID: 'eu-west-1:bcb99f62-8714-4817-a321-3b54d475be9a',
  },
};

const prod = {
  s3: {
    REGION: 'eu-west-1',
    BUCKET: 'kumba-serverless-api-prod-attachmentsbucket-ximu7dt847wr',
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://l1w3ixihfj.execute-api.eu-west-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_91mOgKba9',
    APP_CLIENT_ID: '2uecjtpd3096vu6e6ou8vf2h6e',
    IDENTITY_POOL_ID: 'eu-west-1:c157f92b-e852-491b-a481-c1b7fced7103',
  },
};

// Default to dev if not set
const config = process.env.NODE_ENV === 'production' ? prod : dev;

const mergedConfig = {
  ...awsDefaultConfig,
  ...config,
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
};

export function configure() {
  Amplify.configure(mergedConfig);
  Storage.configure({
    bucket: mergedConfig.Storage.bucket,
    level: 'public',
    region: mergedConfig.Storage.region,
    identityPoolId: mergedConfig.Storage.identityPoolId,
  });

  return mergedConfig;
}

export default mergedConfig;
