import { ROUTE_START, ROUTE_END } from '../constants/action-types';

export function routeStart({ slotId }) {
  return {
    slotId,
    type: ROUTE_START,
  };
}

export function routeEnd({ slotId }) {
  return {
    slotId,
    type: ROUTE_END,
  };
}
