import styled from 'styled-components';

const medicalConsultationsStyledComponent = styled.div`
  .button-actions {
    display: flex;
    justify-content: space-evenly;
  }
`;

export default medicalConsultationsStyledComponent;
