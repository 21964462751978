import { takeEvery, put } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { getText } from 'kumba-common/src/libs/i18n';
import { TimeSlotWhereUniqueInput } from 'kumba-serverless/src/generated/prod/prisma-client';
import {
  TimeSlotCreateInput,
  TimeSlotUpdateInput,
  TimeSlotCircleAreaWhereUniqueInput,
  TimeSlotCircleAreaUpdateInput,
  TimeSlotCircleAreaCreateInput,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import { mutate, query } from 'kumba-common/src/libs/apollo';
import { pick } from 'lodash';
import { getTimeSlot } from '../../graphql-custom/queries';
import {
  SLOT_UPSERT,
  APP_SHOW_MESSAGE,
  SLOT_DELETE,
  SLOT_DUPLICATE,
  SLOT_UPDATE_VET_COMMENT,
} from '../../constants/action-types';
import { handleError, genericSagaHandler } from '../helpers';
import {
  upsertTimeSlot,
  upsertCircleArea,
  deleteTimeSlot,
  slotUpdateVetComment,
} from '../../graphql-custom/mutations';
import { showMessage } from '../../actions/app-actions';
import { SLOTS, SLOT_EDIT } from '../../constants/routes';
import { upsertPolygons } from './helpers';
import { isProd } from 'kumba-common/src/libs/env';

function* slotDeleteHandler(action) {
  const { slotId } = action;

  try {
    const deleteSlotVariable: TimeSlotWhereUniqueInput = {
      id: slotId,
    };

    yield mutate({
      variables: { where: deleteSlotVariable },
      mutation: deleteTimeSlot,
    });

    yield put({
      type: APP_SHOW_MESSAGE,
      message: getText('slots.delete.success', ''),
    });
  } catch (e) {
    yield handleError(e);
  }
}

const RELEVANT_SLOT_FIELDS = [
  'endDate',
  'maxVisits',
  'name',
  'startDate',
  'startFromAddress',
  'destinationAddress',
  'startFromLat',
  'startFromLng',
  'destinationLat',
  'destinationLng',
  'transportationMethod',
  'vetComment',
  'slotType',
];

function* slotDuplicateHandler(action) {
  const { slotId, vetId } = action;

  try {
    const slotData = yield query({
      query: getTimeSlot,
      variables: {
        slotId,
      },
    });
    yield put({
      vetId,
      type: SLOT_UPSERT,
      isUpdateMutation: false,
      isDuplicate: true,
      successMessageId: 'slots.duplicate.success',
      values: {
        ...pick(slotData.timeSlot, RELEVANT_SLOT_FIELDS),
        area: {
          polygons: (slotData.timeSlot.polygonAreas || []).map(polygon => ({
            paths: JSON.parse(polygon.paths),
          })),
        },
      },
    });
  } catch (e) {
    yield handleError(e);
  }
}

function* slotUpsertHandler(action) {
  try {
    const {
      values,
      vetId,
      isUpdateMutation,
      timeSlotId,
      isDuplicate,
      successMessageId,
    } = action;

    const pickedValues = pick(
      values,
      RELEVANT_SLOT_FIELDS
    ) as TimeSlotCreateInput;

    const upsertTimeSlotVariables: {
      where: TimeSlotWhereUniqueInput;
      create: TimeSlotCreateInput;
      update: TimeSlotUpdateInput;
    } = {
      update: {
        ...pickedValues,
        vet: {
          connect: {
            vetId,
          },
        },
      },
      create: {
        ...pickedValues,
        vet: {
          connect: {
            vetId,
          },
        },
      },
      where: {
        id: timeSlotId || 'NO_ID',
      },
    };

    const timeSlot = yield mutate({
      mutation: upsertTimeSlot,
      variables: upsertTimeSlotVariables,
    });

    // Update each circle seperately
    const circles = values.area.circles;
    let circleVariables: {
      where: TimeSlotCircleAreaWhereUniqueInput;
      update: TimeSlotCircleAreaUpdateInput;
      create: TimeSlotCircleAreaCreateInput;
    };
    if (circles && circles.length) {
      for (const circle of circles) {
        const upsertParams = {
          centerLat: circle.center.lat,
          centerLng: circle.center.lng,
          radius: circle.radius,
          timeSlot: {
            connect: {
              id: timeSlotId || timeSlot.upsertTimeSlot.id,
            },
          },
        };
        circleVariables = {
          where: {
            id: circle.id || 'NOT_EXISTING',
          },
          create: {
            ...upsertParams,
          },
          update: {
            ...upsertParams,
          },
        };
        yield mutate({
          variables: circleVariables,
          mutation: upsertCircleArea,
        });
      }
    }

    if (values.area.polygons) {
      yield upsertPolygons({
        polygons: values.area.polygons,
        timeSlotId: timeSlotId || timeSlot.upsertTimeSlot.id,
      });
    }

    let message: string;
    if (successMessageId) {
      message = getText(successMessageId);
    } else if (isUpdateMutation) {
      message = getText('slotCreate.editSuccess');
    } else {
      message = getText('slotCreate.createSuccess');
    }

    yield put(showMessage({ message }));

    let redirectTo = `/${SLOTS}`;
    if (isDuplicate) {
      redirectTo = `/${SLOT_EDIT.replace(
        ':slotId',
        timeSlot.upsertTimeSlot.id
      )}`;
    }
    yield put(push(redirectTo));
  } catch (e) {
    yield handleError(e);
  }
}

function* slotUpdateVetCommentHandler(action) {
  try {
    const { slotId, vetComment } = action;
    isProd() &&
      console.log(
        `Updating vet comment for slot id ${slotId} and comment: ${vetComment}`
      );
    yield mutate({
      mutation: slotUpdateVetComment,
      variables: {
        slotId,
        vetComment,
      },
    });
  } catch (e) {
    yield handleError(e);
  }
}

function* petSaga() {
  yield takeEvery(SLOT_DELETE, genericSagaHandler(slotDeleteHandler));
  yield takeEvery(SLOT_UPSERT, genericSagaHandler(slotUpsertHandler));
  yield takeEvery(SLOT_DUPLICATE, genericSagaHandler(slotDuplicateHandler));
  yield takeEvery(
    SLOT_UPDATE_VET_COMMENT,
    genericSagaHandler(slotUpdateVetCommentHandler)
  );
}

export default petSaga;
