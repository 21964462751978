import {
  PROFILE,
  SLOTS,
  INVENTORY,
  MEDICAL_CONSULTATIONS,
  ACTIVITY,
} from '../constants/routes';
import { getText } from 'kumba-common/src/libs/i18n';

export default [
  { uid: PROFILE, title: getText('menu.profile') },
  { uid: SLOTS, title: getText('menu.slots') },
  { uid: INVENTORY, title: getText('menu.inventory') },
  { uid: ACTIVITY, title: getText('menu.activity') },
  { uid: MEDICAL_CONSULTATIONS, title: getText('menu.medicalConsultations') },
];
