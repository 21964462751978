import styled from 'styled-components';

const slotsStyledComponent = styled.div`
  @media (min-width: 420px) {
    .slots-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      h4 {
        padding-bottom: 0;
      }
    }
  }

  .slot-list {
    width: 100%;
    .button-group {
      padding: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  .no-slots {
    padding: 0.5rem;
  }
  .add-slot {
    margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export default slotsStyledComponent;
