import { ROUTE_START, ROUTE_END } from '../../constants/action-types';
import { takeEvery, put } from 'redux-saga/effects';
import { handleError, genericSagaHandler } from '../helpers';

import { showMessage } from '../../actions/app-actions';

import { getText } from 'kumba-common/src/libs/i18n';
import { getCurrentIsoDate } from 'kumba-utils/src/date';
import { mutate } from 'kumba-common/src/libs/apollo';
import { endRoute, routeStartTrigger } from '../../graphql-custom/mutations';

function* routeStartHandler(action) {
  const { slotId } = action;
  try {
    yield mutate({
      mutation: routeStartTrigger,
      variables: {
        data: {
          slotId,
        },
      },
    });
    const message = getText('route.start.success');

    yield put(showMessage({ message }));
  } catch (e) {
    yield handleError(e);
  }
}

function* routeEndHandler(action) {
  const { slotId } = action;
  try {
    yield mutate({
      mutation: endRoute,
      variables: {
        routeId: slotId,
        actualEndDate: getCurrentIsoDate(),
      },
    });

    const message = getText('route.end.success');
    yield put(showMessage({ message }));
  } catch (e) {
    yield handleError(e);
  }
}

function* routeSaga() {
  yield takeEvery(ROUTE_START, genericSagaHandler(routeStartHandler));
  yield takeEvery(ROUTE_END, genericSagaHandler(routeEndHandler));
}

export default routeSaga;
