export const updateVetProfile = `mutation updateVetProfile($data:VetUpdateInput!, $where:VetWhereUniqueInput!) {
  updateVet(where:$where, data:$data) {
    vetId
    user {
      email
    }
  }
}`;

export const upsertTimeSlot = `mutation upsertTimeSlot(
  $where:TimeSlotWhereUniqueInput!, 
  $create:TimeSlotCreateInput!, 
  $update:TimeSlotUpdateInput!) {
  upsertTimeSlot(where:$where, create:$create, update:$update) {
    id
    maxVisits
    startDate
    endDate
    name
    circleAreas {
      id
      radius
      centerLat
      centerLng
    }
    
  }
}`;

export const upsertCircleArea = `mutation upsertCircleArea(
  $where:TimeSlotCircleAreaWhereUniqueInput!,
  $update:TimeSlotCircleAreaUpdateInput!,
  $create:TimeSlotCircleAreaCreateInput!
) {
  upsertTimeSlotCircleArea(where:$where, create:$create, update:$update) {
    id
    radius
    centerLat
    centerLng
  }
}`;

export const upsertPolygonArea = `mutation upsertPolygonArea(
  $where:TimeSlotPolygonAreaWhereUniqueInput!,
  $update:TimeSlotPolygonAreaUpdateInput!,
  $create:TimeSlotPolygonAreaCreateInput!
) {
  upsertTimeSlotPolygonArea(where:$where, create:$create, update:$update) {
    id
    paths
  }
}`;

export const deleteTimeSlot = `mutation deleteTimeSlot($where: TimeSlotWhereUniqueInput!) {
  deleteTimeSlot(where:$where) {
    id
  }
}`;

export const endRoute = `mutation endRoute($routeId:ID!,$actualEndDate:DateTime!) {
  updateTimeSlot(where:{
    id: $routeId
  },
  data:{
    actualEndDate: $actualEndDate
  }) {
    id 
    actualEndDate
  }
}`;

export const startAppointment = `mutation startAppointment($appointmentId:ID!, $actualStartDate:DateTime!) {
  updateAppointment(where:{
    id: $appointmentId
  },
  data:{
    actualStartDate: $actualStartDate
  }) {
    id 
    actualStartDate
  }
}`;

export const startAppointmentNoShow = `mutation startAppointmentNoShow($appointmentId: ID!) {
  updateAppointment(
    data: { isNoShowStarted: true }
    where: { id: $appointmentId }
  ) {
    id
    isNoShowStarted
  }
}
`;

export const createVet = `mutation createVet($vetId: String!, $email:String!) {
    createVet(data:{
      vetId: $vetId,
      user:{
        create:{
          userId:$vetId,
          email:$email
        }
      }
    }) {
      vetId
      user {
        email
        userId
      }
    }
  }`;

export const appointmentEditTrigger = `mutation appointmentEditTrigger($data:appointmentEditInput!) {
  appointmentEdit(data:$data){
    id
  }
}
`;

export const appointmentEndTrigger = `mutation appointmentEndTrigger($data:appointmentEndInput!) {
  appointmentEnd(data:$data){
    id
  }
}
`;

export const routeStartTrigger = `mutation routeStartTrigger($data:routeStartInput!) {
  routeStart(data:$data){
    id
  }
}
`;

export const supplyCreateTrigger = `mutation supplyCreateTrigger($data:SupplyCreateInput!) {
  supplyCreate(data:$data) {
    id
  }
}
`;

export const inventoryQuantityUpdate = `mutation inventoryQuantityUpdate(
	$productInventoryId: ID!
	$remainingQuantity: Float!
) {
	updateVetProductInventory(
		data: { remainingQuantity: $remainingQuantity }
		where: { id: $productInventoryId }
	) {
		id
	}
}
`;

export const createScheduledMessage = `mutation createScheduledMessage(
  # Either an email or phone number
  $to: String!
  $channel: MessageChannel!
  $sendingDate:DateTime!
  $recipientUserId:String
  $content: String!
  $messageType: MessageType
) {
  createMessage(data:{
    from: "noreply@thekumba.com",
    to:$to,
    channel: $channel,
    sendingDate: $sendingDate,
    content:$content,
    messageType: $messageType,
    recipientUserId:$recipientUserId,
    status: PENDING
  }) {
    id
    sendingDate
  }
}`;

export const slotUpdateVetComment = `mutation slotUpdateVetComment($vetComment: String!, $slotId: ID!) {
  updateTimeSlot(data: { vetComment: $vetComment }, where: { id: $slotId }) {
    id
  }
}
`;

export const upsertMedicalConsultationMutation = `mutation upsertMedicalConsultation(
	$consultationDate: DateTime!
	$summary: String!
	$petId: ID!
	$vetId: String!
	$documentGroupUrl: String
	$consultationId: ID!
) {
	upsertMedicalConsultation(
		create: {
			consultationDate: $consultationDate
			summary: $summary
			documentGroupUrl: $documentGroupUrl
			consultedPet: { connect: { id: $petId } }
			consultingVet: { connect: { vetId: $vetId } }
		}
		update: {
			consultationDate: $consultationDate
			summary: $summary
			documentGroupUrl: $documentGroupUrl
			consultedPet: { connect: { id: $petId } }
			consultingVet: { connect: { vetId: $vetId } }
		}
		where: { id: $consultationId }
	) {
		id
	}
}
`;
