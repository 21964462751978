import * as React from 'react';

import Form, {
  InputTypes,
  InputDefinition,
} from 'kumba-components/src/components/Form';
import { connect } from 'react-redux';

import { authForgotPassword } from '../../actions/auth-actions';
import initialState from '../../reducers/initial-state';
import ForgotPasswordStyledComponent from './styled-component';
import { Typography } from '@material-ui/core';
import { getText } from 'kumba-common/src/libs/i18n';
import getDefaultErrorMessages from 'kumba-common/src/constants/error-messages';

interface LoginProps {
  history: any;
  location: any;

  authForgotPassword: any;
  isForgotPasswordConfirmed: boolean;
}

class Login extends React.Component<LoginProps> {
  loginInputDefinitions: InputDefinition[] = [
    {
      inputType: InputTypes.Email,
      label: getText('signup.form.email.label'),
      name: 'email',
      isRequired: true,
    },
  ];

  confirmInputDefinition: InputDefinition[] = [
    {
      inputType: InputTypes.Text,
      label: getText('forgotPassword.confirmationCode'),
      name: 'code',
      isRequired: true,
    },
    {
      inputType: InputTypes.Password,
      label: getText('forgotPassword.newPassword'),
      name: 'password',
      isRequired: true,
    },
  ];

  render() {
    let inputDefinitions = this.loginInputDefinitions;
    if (this.props.isForgotPasswordConfirmed) {
      inputDefinitions = inputDefinitions.concat(this.confirmInputDefinition);
    }

    return (
      <ForgotPasswordStyledComponent>
        {this.props.isForgotPasswordConfirmed && (
          <Typography variant="h6">
            {getText('forgotPassword.helpText')}
          </Typography>
        )}
        <Form
          formTitle={getText('forgotPassword.title')}
          inputDefinitions={inputDefinitions}
          onSubmit={this.props.authForgotPassword}
          submitLabel={getText('common.buttons.send')}
          errorMessages={getDefaultErrorMessages()}
        />
      </ForgotPasswordStyledComponent>
    );
  }
}

const mapStateToProps = (state = initialState, ownProps) => {
  return {
    isForgotPasswordConfirmed: state.auth.isForgotPasswordConfirmed,
  };
};
export default connect(
  mapStateToProps,
  { authForgotPassword }
)(Login);
