import * as React from 'react';

import Form, {
  InputTypes,
  InputDefinition,
} from 'kumba-components/src/components/Form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as queryString from 'query-string';

import { authLogin } from '../../actions/auth-actions';
import initialState from '../../reducers/initial-state';
import { getText } from 'kumba-common/src/libs/i18n';
import Button from 'kumba-components/src/components/Button';
import { FORGOT_PASSWORD, SIGNUP } from '../../constants/routes';
import LoginStyledComponent from './styled-component';
import getDefaultErrorMessages from 'kumba-common/src/constants/error-messages';
interface LoginProps {
  history: any;
  location: any;
  authLogin: any;
  isLoggedIn: boolean;
}

class Login extends React.Component<LoginProps> {
  loginInputDefinitions: InputDefinition[] = [
    {
      inputType: InputTypes.Email,
      label: getText('signup.form.email.label'),
      name: 'email',
      isRequired: true,
    },
    {
      inputType: InputTypes.Password,
      label: getText('signup.form.password.label'),
      name: 'password',
      isRequired: true,
      initialValue: '',
    },
  ];

  getRedirectUrl() {
    return queryString.parse(this.props.location.search).redirect || '/';
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push(this.getRedirectUrl());
    }
  }

  render() {
    return (
      <LoginStyledComponent>
        <Form
          formTitle={getText('login.title')}
          formSubtitle={
            <div>
              {getText('login.subtitle')}&nbsp;
              <Link to={`/${SIGNUP}`}>{getText('signup')}</Link>
            </div>
          }
          inputDefinitions={this.loginInputDefinitions}
          onSubmit={this.props.authLogin}
          submitLabel={getText('common.buttons.login')}
          errorMessages={getDefaultErrorMessages()}
        />
        <div className="forgot-password">
          <Link to={`/${FORGOT_PASSWORD}`}>
            <Button variant="text">
              {getText('common.buttons.forgotPassword')}
            </Button>
          </Link>
        </div>
      </LoginStyledComponent>
    );
  }
}

const mapStateToProps = (state = initialState, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};
export default connect(
  mapStateToProps,
  { authLogin }
)(Login);
