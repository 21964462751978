import {
  SLOT_GET,
  SLOT_DELETE,
  SLOT_UPSERT,
  SLOT_DUPLICATE,
  SLOT_UPDATE_VET_COMMENT,
} from '../constants/action-types';

export function slotGet(slotId) {
  return {
    slotId,
    type: SLOT_GET,
  };
}

export function slotUpsert({ isUpdateMutation, values, vetId, timeSlotId }) {
  return {
    isUpdateMutation,
    vetId,
    values,
    timeSlotId,
    type: SLOT_UPSERT,
  };
}

export function slotDelete(slotId) {
  return {
    slotId,
    type: SLOT_DELETE,
  };
}

export function slotDuplicate({ slotId, vetId }) {
  return {
    slotId,
    vetId,
    type: SLOT_DUPLICATE,
  };
}

export function slotUpdateVetComment({ slotId, vetComment }) {
  return {
    slotId,
    vetComment,
    type: SLOT_UPDATE_VET_COMMENT,
  };
}
