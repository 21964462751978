export const APP_INIT = 'APP_INIT';
export const APP_LANGUAGE_CHANGED = 'APP_LANGUAGE_CHANGED';
export const APP_FAILURE = 'APP_FAILURE';
export const APP_SHOW_MESSAGE = 'APP_SHOW_MESSAGE';
export const APP_SHOW_SPINNER = 'APP_SHOW_SPINNER';
export const APP_HIDE_SPINNER = 'APP_HIDE_SPINNER';

export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SOCIAL = 'AUTH_LOGIN_SOCIAL';

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_SET = 'AUTH_SET';

export const PET_OWNER_UPDATE = 'PET_OWNER_UPDATE';

export const PET_UPSERT = 'PET_UPSERT';

export const APPOINTMENT_CREATE = 'APPOINTMENT_CREATE';
export const APPOINTMENT_REMINDER_CREATE = 'APPOINTMENT_REMINDER_CREATE';
export const APPOINTMENT_DELETE = 'APPOINTMENT_DELETE';

export const PET_LIST_GET = 'PET_LIST_GET';
export const PET_LIST_SET = 'PET_LIST_SET';
export const PET_GET = 'PET_GET';
export const PET_SET = 'PET_SET';
export const PET_DELETE = 'PET_DELETE';

export const ADMIN_MANUAL_PAYMENT_CREATE = 'ADMIN_MANUAL_PAYMENT_CREATE';
