export enum NoShowActionTypes {
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  NO_SHOW_START,
}

const NO_SHOW_WAITING_TIME_MINUTES = 10;
export interface NoShowState {
  noShowStartDate: number;
  isConfirmShown: boolean;
}
export function noShowReducer(state: NoShowState, action) {
  switch (action.type) {
    case NoShowActionTypes.SHOW_CONFIRM:
      return {
        ...state,
        isConfirmShown: true,
      };

    case NoShowActionTypes.HIDE_CONFIRM:
      return {
        ...state,
        isConfirmShown: false,
      };
    case NoShowActionTypes.NO_SHOW_START: {
      const remainingNoShowMinutes = getRemainingTimerMinutes({
        noShowStartDate: action.noShowStartDate,
      });

      return {
        ...state,
        noShowStartDate:
          remainingNoShowMinutes &&
          remainingNoShowMinutes <= NO_SHOW_WAITING_TIME_MINUTES
            ? action.noShowStartDate
            : null,
      };
    }
    default:
      return state;
  }
}

export function getRemainingTimerMinutes({ noShowStartDate }) {
  return noShowStartDate
    ? Math.max(
        NO_SHOW_WAITING_TIME_MINUTES -
          (Date.now() - noShowStartDate) / 1000 / 60,
        0
      )
    : null;
}
