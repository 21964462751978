import * as React from 'react';
import { connect } from 'react-redux';

import { compose, graphql } from 'react-apollo';

import SlotCreateStyledComponent from './styled-component';
import Spinner from 'kumba-components/src/components/Spinner';

import gql from 'graphql-tag';
import initialState from '../../reducers/initial-state';
import Form from 'kumba-components/src/components/Form';
import {
  updateWithValues,
  formatValues,
  isTimeSufficient,
  updateWithInitialValues,
  isNewSlotContainedInOldSlot,
} from './helpers';
import slotInputDefinitions from './slot-input-definition';
import { KumbaCircle } from 'kumba-components/src/components/GoogleMap/interfaces';
import { slotUpsert } from '../../actions/slot-actions';
import { getTimeSlot, getVetSlotDetails } from '../../graphql-custom/queries';
import { getText } from 'kumba-common/src/libs/i18n';
import {
  TimeSlot,
  User,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import { showMessage } from '../../actions/app-actions';
import { ToastTypes } from 'kumba-components/src/components/Toast';
import {
  getTimestampFromStringDate,
  DAY_IN_TIMESTAMP,
} from 'kumba-utils/src/date';
import getDefaultErrorMessages from 'kumba-common/src/constants/error-messages';
import { get } from 'lodash';
import { Checkbox } from 'kumba-components/src/components/CheckboxGroup';

interface SlotCreateProps {
  id: string;
  isAdmin: boolean;
  showMessage: any;
  slotUpsert: any;
  match: any;
  vetData: { loading: boolean; user: User };
  data: { loading: boolean; timeSlot: TimeSlot };
}

interface SlotCreateState {
  circles: KumbaCircle[];
  isLoading: boolean;
  originalSlotValues: TimeSlot | null;
  shouldDisableFields: boolean;
}

class SlotCreateComponent extends React.Component<
  SlotCreateProps,
  SlotCreateState
> {
  state = {
    circles: [],
    isLoading: false,
    originalSlotValues: null,
    shouldDisableFields: true,
  };

  areValidValues = ({ startTime, endTime, date, area, maxVisits }) => {
    const startDate = `${date}T${startTime}`;
    const endDate = `${date}T${endTime}`;
    const start = getTimestampFromStringDate(startDate);
    const end = getTimestampFromStringDate(endDate);

    const hasAppointments = !!get(
      this,
      'props.data.timeSlot.appointments.length'
    );
    const areNewSlotsContainedInOriginalSlots = hasAppointments
      ? isNewSlotContainedInOldSlot({
          oldTimeSlot: {
            startDate: (this.state.originalSlotValues as any).startDate,
            endDate: (this.state.originalSlotValues as any).endDate,
          },
          newTimeSlot: {
            startDate,
            endDate,
          },
        })
      : true;

    // Date can only be at least 24 hrs forward
    return (
      !!area &&
      !!areNewSlotsContainedInOriginalSlots &&
      start < end &&
      start - Date.now() > DAY_IN_TIMESTAMP &&
      isTimeSufficient({ maxVisits, startTimestamp: start, endTimestamp: end })
    );
  };

  inputDefinitions = slotInputDefinitions;

  onSubmit = ({ values, isUpdateMutation }) => {
    // Admins can basically change everything
    if (this.areValidValues(values) || this.props.isAdmin) {
      this.setState({
        isLoading: true,
      });
      this.props.slotUpsert({
        isUpdateMutation,
        values: formatValues(values),
        vetId: this.props.id,
        timeSlotId: this.props.match.params.slotId,
      });
    } else {
      this.props.showMessage({
        message: (
          <div>
            <div>Please ensure that:</div>
            <ul>
              <li>- You have selected a service area</li>
              <li>- Selected time slot is at least 24 hours from now</li>
              <li>- Time frame is sufficient for selected number of visits</li>
              <li>
                - Updated time slots include part of initial time slot (if pets
                are scheduled)
              </li>
            </ul>
          </div>
        ),
        messageType: ToastTypes.Error,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.data &&
      this.props.data.timeSlot &&
      !this.state.originalSlotValues
    ) {
      this.setState({
        originalSlotValues: {
          ...this.props.data.timeSlot,
        },
      });
    }
  };

  componentDidMount = () => {
    // Focus on the date as it's the only thing we need to change - only if isUpdateMutation
    !!this.props.match.params.slotId &&
      setTimeout(() => {
        const datePicker = document.querySelector('.kumba-date-picker');
        !!datePicker && datePicker.scrollIntoView({ behavior: 'smooth' });
      }, 3000);
  };

  render() {
    let formTitle;
    let inputDefinitions = this.inputDefinitions;
    const isUpdateMutation = !!this.props.match.params.slotId;

    if (
      (isUpdateMutation && this.props.data.loading) ||
      this.props.vetData.loading
    ) {
      return <Spinner />;
    } else {
      if (isUpdateMutation) {
        // Edit time slot
        inputDefinitions = updateWithValues({
          inputDefinitions: this.inputDefinitions,
          slot: this.props.data.timeSlot,
          shouldDisableFields: this.state.shouldDisableFields,
        });

        formTitle = getText('slotCreate.edit');
      } else {
        // Create Time Slot
        inputDefinitions = updateWithInitialValues({
          inputDefinitions: this.inputDefinitions,
          vet: this.props.vetData.user,
        });

        formTitle = getText('slotCreate.title');
      }

      const hasAppointments = !!get(
        this,
        'props.data.timeSlot.appointments.length'
      );

      return (
        <SlotCreateStyledComponent>
          {this.state.isLoading && <Spinner />}
          {!!hasAppointments && !!this.props.isAdmin && (
            <Checkbox
              value={this.state.shouldDisableFields.toString()}
              isChecked={this.state.shouldDisableFields}
              onChange={() =>
                this.setState({
                  shouldDisableFields: !this.state.shouldDisableFields,
                })
              }
              label={getText('slotCreate.shouldDisableFields')}
            />
          )}
          <Form
            formTitle={formTitle}
            alertMessage={
              hasAppointments ? getText('slotCreate.hasAppointments') : ''
            }
            submitLabel={getText('common.buttons.save')}
            inputDefinitions={inputDefinitions}
            errorMessages={getDefaultErrorMessages()}
            onSubmit={values =>
              this.onSubmit({
                values,
                isUpdateMutation,
              })
            }
            context={{ isAdmin: this.props.isAdmin }}
          />
        </SlotCreateStyledComponent>
      );
    }
  }
}
const mapStateToProps = (state = initialState) => {
  return {
    userId: state.auth.userId,
    isAdmin: state.auth.isAdmin,
  };
};

export default compose(
  graphql(gql(getTimeSlot), {
    skip: (props: any) => !props.match.params.slotId,
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: { slotId: props.match.params.slotId },
    }),
  }),
  graphql(gql(getVetSlotDetails), {
    name: 'vetData',
    options: (props: any) => ({
      fetchPolicy: 'cache-and-network',
      variables: { vetId: props.id },
    }),
  }),

  connect(
    mapStateToProps,
    { slotUpsert, showMessage }
  )
)(SlotCreateComponent);
