import { formatStringDate, getWeekdayFromDate } from 'kumba-utils/src/date';
import { get } from 'lodash';

export function flattenSlotData(slot) {
  const startEnd = get(slot, 'timeSlots.items.0');
  if (startEnd) {
    const { startDate, endDate } = startEnd;
    return {
      ...slot,
      timeSlotId: get(slot, 'timeSlots.items.0.id'),
      startDate: formatStringDate({ timestamp: startDate }),
      endDate: formatStringDate({ timestamp: endDate }),
    };
  } else {
    return {
      ...slot,
    };
  }
}

export function getActiveSlots(slots) {
  return slots.filter(s => {
    return !s.actualEndDate;
  });
}

export function formatSlotName({ startDate, endDate, name }) {
  const weekday = getWeekdayFromDate({ stringDate: startDate });
  const formattedStartDate = formatStringDate({
    stringDate: startDate,
    showTime: true,
  });
  const formattedEndDate = formatStringDate({
    stringDate: endDate,
    format: 'HH:mm',
  });

  return `${weekday}, ${formattedStartDate}-${formattedEndDate} ${name}`;
}
