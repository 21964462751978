import {
  TimeSlotPolygonAreaWhereUniqueInput,
  TimeSlotPolygonAreaUpdateInput,
  TimeSlotPolygonAreaCreateInput,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import { mutate } from 'kumba-common/src/libs/apollo';
import { upsertPolygonArea } from '../../graphql-custom/mutations';

export async function upsertPolygons({ polygons, timeSlotId }) {
  let polygonVariables: {
    where: TimeSlotPolygonAreaWhereUniqueInput;
    update: TimeSlotPolygonAreaUpdateInput;
    create: TimeSlotPolygonAreaCreateInput;
  };
  if (polygons && polygons.length) {
    for (const currPolygon of polygons) {
      const upsertParams = {
        timeSlot: {
          connect: {
            id: timeSlotId,
          },
        },
        paths: JSON.stringify(currPolygon.paths),
      };
      polygonVariables = {
        where: {
          id: currPolygon.id || 'NOT_EXISTING',
        },
        create: {
          ...upsertParams,
        },
        update: {
          ...upsertParams,
        },
      };
      await mutate({
        variables: polygonVariables,
        mutation: upsertPolygonArea,
      });
    }
  }
}
