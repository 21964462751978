export enum SupplyActionTypes {
  UPDATE_PRODUCT_ID,
  UPDATE_QUANTITY,
  ADD_PRODUCT,
  DELETE_PRODUCT,
}
export function selectedProductsReducer(
  state: any[],
  action: {
    type: SupplyActionTypes;
    productId?: string;
    oldProductId?: string;
    quantity?: number;
  }
) {
  switch (action.type) {
    case SupplyActionTypes.UPDATE_PRODUCT_ID:
      return state.map(s => {
        const newProductId =
          action.oldProductId === s.id ? action.productId : s.id;
        return {
          ...s,
          id: newProductId,
        };
      });

    case SupplyActionTypes.UPDATE_QUANTITY:
      return state.map(s => ({
        ...s,
        quantity: s.id === action.productId ? action.quantity : s.quantity,
      }));

    case SupplyActionTypes.ADD_PRODUCT:
      return state.concat([{ id: '', quantity: 0 }]);
    case SupplyActionTypes.DELETE_PRODUCT:
      return state.filter(s => s.id !== action.productId);
    default:
      return state;
  }
}
