export default {
  auth: {
    email: '',
    password: '',
    userId: '',
    isLoggedIn: null,
    isForgotPasswordConfirmed: false,
    isAdmin: false,
  },
  app: {
    toasts: [],
    isSpinnerShown: false,
    direction: 'ltr',
  },
  vet: {
    firstName: '',
    lastName: '',
    license: '',
  },
};
