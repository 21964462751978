import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reduxSaga from 'redux-saga';

import createRootReducer from '../reducers';
import sagas from '../sagas';
import initialState from '../reducers/initial-state';
import { getReduxMiddleware } from 'kumba-common/src/libs/logrocket';

export const history = createBrowserHistory();

// Create the saga middleware
const sagaMiddleware = reduxSaga();
// Mount it on the Store
const store = createStore(
  createRootReducer(history), // Root reducer with router state
  initialState as any,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      getReduxMiddleware()
    ),
    // If you are using the devToolsExtension, you can add it here also
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

// Then run the saga
sagaMiddleware.run(sagas);

export default store;
