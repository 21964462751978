import styled from 'styled-components';
import { ERROR } from 'kumba-common/src/constants/colors.constant';

const appointmentStyledComponent = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 80%;
  @media (max-width: 420px) {
    grid-template-columns: 100%;
  }
  margin-bottom: 2rem;

  .accordion {
    margin-bottom: 1.5rem;
  }

  .input-textarea {
    width: 100%;
  }

  .kumba-checkbox-group-helper {
    color: ${ERROR};
  }

  .appointment-edit-disabable-elements {
    border: 0;
    color: gray;
  }
`;

export default appointmentStyledComponent;
