export const APP_INIT = 'APP_INIT';
export const APP_SHOW_MESSAGE = 'APP_SHOW_MESSAGE';
export const APP_SHOW_SPINNER = 'APP_SHOW_SPINNER';
export const APP_HIDE_SPINNER = 'APP_HIDE_SPINNER';

export const VET_CREATE = 'VET_CREATE';

export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_SET = 'AUTH_SET';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const APP_FAILURE = 'APP_FAILURE';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';

export const VET_UPDATE = 'VET_UPDATE';

export const SLOT_DELETE = 'SLOT_DELETE';
export const SLOT_CREATE = 'SLOT_CREATE';
export const SLOT_GET = 'SLOT_GET';
export const SLOT_UPSERT = 'SLOT_UPSERT';
export const SLOT_UPDATE_VET_COMMENT = 'SLOT_UPDATE_VET_COMMENT';
export const SLOT_DUPLICATE = 'SLOT_DUPLICATE';

export const ROUTE_START = 'ROUTE_START';
export const ROUTE_END = 'ROUTE_END';

export const APPOINTMENT_START = 'APPOINTMENT_START';
export const APPOINTMENT_EDIT = 'APPOINTMENT_EDIT';
export const APPOINTMENT_END = 'APPOINTMENT_END';
export const APPOINTMENT_NO_SHOW_START = 'APPOINTMENT_NO_SHOW_START';

export const SUPPLY_CREATE = 'SUPPLY_CREATE';
