import LatLng from '../interfaces/lat-lng.interface';
import TransportationMethods from '../enums/transportation-methods';

export interface DirectionUrlProps {
  origin: LatLng;
  destination: LatLng;
  waypoints?: LatLng[];
  travelMode: TransportationMethods;
}

export function getDirectionsUrl(params: DirectionUrlProps) {
  const {
    origin,
    destination,
    waypoints,
    travelMode = TransportationMethods.DRIVING,
  } = params;

  const originUrl = `origin=${origin.lat},${origin.lng}`;
  const destinationUrl = `destination=${destination.lat},${destination.lng}`;
  const waypointsUrl = `waypoints=${(waypoints || [])
    .map((wp, i) => `${i && '|'}${wp.lat},${wp.lng}`)
    .join('')}`;

  return `https://www.google.com/maps/dir/?api=1&${originUrl}&${destinationUrl}&${waypointsUrl}&travelmode=${travelMode.toLowerCase()}`;
}
