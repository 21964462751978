import {
  APP_FAILURE,
  APP_SHOW_MESSAGE,
  APP_SHOW_SPINNER,
  APP_HIDE_SPINNER,
} from './../constants/action-types';
import initialState from './initial-state';
import { ToastTypes } from 'kumba-components/src/components/Toast';
import { APP_INIT } from '../../../kumba-pets/src/constants/action-types';

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case APP_FAILURE: {
      const toast = action.errorMessage
        ? { toastType: ToastTypes.Error, message: action.errorMessage }
        : null;

      if (toast) {
        // Meaning there is a translated text for the error
        return {
          ...state,
          toasts: (state.toasts as any).concat(toast),
        };
      } else {
        console.error('Unknown error', action.error);
        return state;
      }
    }
    case APP_SHOW_MESSAGE:
      return {
        ...state,
        toasts: (state.toasts as any).concat({
          message: action.message,
          toastType: action.messageType || ToastTypes.Success,
        }),
      };
    case APP_SHOW_SPINNER:
      return {
        ...state,
        isSpinnerShown: true,
      };

    case APP_HIDE_SPINNER:
      return {
        ...state,
        isSpinnerShown: false,
      };

    case APP_INIT:
      return {
        ...state,
        direction: action.direction,
      };
    default:
      return state;
  }
}
