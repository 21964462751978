/* eslint-disable capitalized-comments */
import { APP_SHOW_MESSAGE, SUPPLY_CREATE } from './../constants/action-types';
import { takeEvery, put } from 'redux-saga/effects';

import { getText } from 'kumba-common/src/libs/i18n';
import { mutate } from 'kumba-common/src/libs/apollo';
import { supplyCreateTrigger } from '../graphql-custom/mutations';
import { genericSagaHandler, handleError } from './helpers';

function* supplyCreateHandler(action) {
  const { vetId, supplyProducts } = action;
  try {
    yield mutate({
      mutation: supplyCreateTrigger,
      variables: {
        data: {
          vetId,
          supplyProducts,
        },
      },
    });

    yield put({
      type: APP_SHOW_MESSAGE,
      message: getText('inventory.supply.added'),
    });
  } catch (e) {
    handleError(e);
    console.error('Problem creating supply', e);
  }
}

function* inventorySaga() {
  yield takeEvery(SUPPLY_CREATE, genericSagaHandler(supplyCreateHandler));
}

export default inventorySaga;
