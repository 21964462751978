import styled from 'styled-components';

const DashboardStyledComponent = styled.div`
  .scorecard-row {
    display: flex;
    justify-content: space-evenly;
  }
`;

export default DashboardStyledComponent;
