import * as React from 'react';
import {
  InputDefinition,
  InputTypes,
} from 'kumba-components/src/components/Form';
import {
  getCurrentDateString,
  dateToString,
  addUnitsToNow,
  getStringDateFromTimestamp,
  DAY_IN_TIMESTAMP,
} from 'kumba-utils/src/date';
import TransportationMethods from 'kumba-common/src/enums/transportation-methods';
import { getText } from 'kumba-common/src/libs/i18n';
import TimeUnits from 'kumba-utils/src/enums/time-units';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SlotType from 'kumba-common/src/enums/slot-type';

const TransportationMethodToIconMap: {
  [key in TransportationMethods]: any;
} = {
  [TransportationMethods.WALKING]: <DirectionsWalkIcon />,
  [TransportationMethods.BICYCLING]: <DirectionsBikeIcon />,
  [TransportationMethods.DRIVING]: <DirectionsCarIcon />,
};

function generateTransportationMethodsOptions() {
  return Object.keys(TransportationMethods).map(tm => ({
    label: TransportationMethodToIconMap[tm],
    value: tm,
  }));
}

const slotInputDefinitions: InputDefinition[] = [
  {
    inputType: InputTypes.Text,
    name: 'name',
    isRequired: true,
    label: getText('slotCreate.name'),
    helperText: getText('slotCreate.nameHelper'),
  },
  {
    inputType: InputTypes.AreaSelector,
    name: 'area',
    isRequired: false,
    helperText: getText('slotCreate.area.titleHelper'),
    label: getText('slotCreate.area.title'),
  },
  {
    inputType: InputTypes.Address,
    label: getText('slotCreate.startFromAddress'),
    name: 'startFromAddress',
    isRequired: true,
  },
  {
    inputType: InputTypes.Address,
    label: getText('slotCreate.destinationAddress'),
    name: 'destinationAddress',
    isRequired: true,
  },
  {
    inputType: InputTypes.ToggleButtons,
    name: 'transportationMethod',
    isRequired: true,
    initialValue: 'DRIVING',
    label: getText('slotCreate.transportationMethod'),
    options: generateTransportationMethodsOptions(),
  },
  {
    inputType: InputTypes.Date,
    name: 'date',
    isRequired: true,
    helperText: getText('slotCreate.dateHelper'),
    showTime: false,
    initialValue: getStringDateFromTimestamp(
      Date.now() + 2 * DAY_IN_TIMESTAMP,
      {
        format: 'YYYY-MM-DD',
      }
    ),
    label: getText('date'),
  },
  {
    inputType: InputTypes.Date,
    name: 'startTime',
    isRequired: true,
    showDate: false,
    showTime: true,
    initialValue: getCurrentDateString({ showDate: false, showTime: true }),
    label: getText('slotCreate.startTime'),
    helperText: getText('slotCreate.startTimeHelper'),
  },
  {
    inputType: InputTypes.Date,
    name: 'endTime',
    isRequired: true,
    showDate: false,
    showTime: true,
    initialValue: dateToString(
      addUnitsToNow({ unit: TimeUnits.HOURS, amount: 3 }),
      {
        showDate: false,
        showTime: true,
      }
    ),
    label: getText('slotCreate.endTime'),
    helperText: getText('slotCreate.endTimeHelper'),
  },
  {
    inputType: InputTypes.Number,
    name: 'maxVisits',
    isRequired: true,
    minValue: 1,
    maxValue: 15,
    initialValue: 5,
    label: getText('slotCreate.maxVisits'),
    helperText: getText('slotCreate.maxVisitsHelper'),
  },
  {
    inputType: InputTypes.RadioButtons,
    name: 'slotType',
    isRequired: true,
    initialValue: SlotType.PREVENTIVE,
    label: getText('slotCreate.slotType'),
    options: [
      { label: SlotType.PREVENTIVE, value: SlotType.PREVENTIVE },
      { label: SlotType.MEDICAL, value: SlotType.MEDICAL },
    ],
    isShownCb: ({ context }) => !!context.isAdmin,
  },
];

export default slotInputDefinitions;
