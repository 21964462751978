import { VET_UPDATE, APP_SHOW_MESSAGE } from './../constants/action-types';
import { takeEvery, put, select } from 'redux-saga/effects';
import { selectUserId } from './selectors';
import {
  VetUpdateInput,
  VetWhereUniqueInput,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import { updateVetProfile } from '../graphql-custom/mutations';
import { mutate } from 'kumba-common/src/libs/apollo';
import { getText } from 'kumba-common/src/libs/i18n';
import { genericSagaHandler } from '../../../kumba-pets/src/sagas/helpers';
import { getTimezoneFromLatLng, setTimezone } from 'kumba-utils/src/date';

function* vetUpdateHandler(action) {
  try {
    const {
      firstName,
      lastName,
      license,
      phone,
      tShirtSize,
      address,
      addressLat,
      addressLng,
      profilePictureUrl = null,
    } = action;
    const userId = yield select(selectUserId);

    const vetWhere: VetWhereUniqueInput = {
      vetId: userId,
    };
    const timezone = getTimezone({ addressLat, addressLng });
    const vetUpdateInput: VetUpdateInput = {
      license,
      tShirtSize,
      vetId: userId,
      user: {
        update: {
          address,
          addressLat,
          addressLng,
          firstName,
          lastName,
          phone,
          profilePictureUrl,
          timezone,
        },
      },
    };
    yield mutate({
      mutation: updateVetProfile,
      variables: {
        where: vetWhere,
        data: vetUpdateInput,
      },
    });

    yield put({
      type: APP_SHOW_MESSAGE,
      message: getText('profile.update.success'),
    });
    setTimezone(timezone);
  } catch (error) {
    console.error('Problem in update vet', error);
  }
}

function getTimezone({ addressLat, addressLng }) {
  return addressLat && addressLng
    ? getTimezoneFromLatLng({ lat: addressLat, lng: addressLng })
    : undefined;
}

function* petSaga() {
  yield takeEvery(VET_UPDATE, genericSagaHandler(vetUpdateHandler));
}

export default petSaga;
