import { SUPPLY_CREATE } from '../constants/action-types';

export function supplyCreate({ selectedProducts, vetId }) {
  return {
    vetId,
    type: SUPPLY_CREATE,
    supplyProducts: selectedProducts.map(sp => ({
      productId: sp.id,
      quantity: Number(sp.quantity),
    })),
  };
}
