import {
  AUTH_SET,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD_SUCCESS,
} from './../constants/action-types';
import initialState from './initial-state';

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case AUTH_SET: {
      const { userId, email, password, isAdmin, actualUserId } = action;

      return {
        ...state,
        userId,
        email,
        password,
        isAdmin,
        actualUserId,
      };
    }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordConfirmed: true,
      };
    default:
      return state;
  }
}
