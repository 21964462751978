import * as React from 'react';
import DirectionsIcon from '@material-ui/icons/Directions';

import GoogleMap from 'kumba-components/src/components/GoogleMap';
import { getText } from 'kumba-common/src/libs/i18n';
import { getDirectionsUrl } from '../../../../kumba-common/src/libs/google-maps';
import TransportationMethods from 'kumba-common/src/enums/transportation-methods';
// @ts-ignore
import DogIconBlue from 'kumba-common/src/images/dog-icon-blue.png';
// @ts-ignore
import DogIconRed from 'kumba-common/src/images/dog-icon-red.png';
// @ts-ignore
import CatIconBlue from 'kumba-common/src/images/cat-icon-blue.png';
// @ts-ignore
import CatIconRed from 'kumba-common/src/images/cat-icon-red.png';
// @ts-ignore
import CatDogIcon from 'kumba-common/src/images/cat-dog.png';

import { SlotRoute, RouteAppointment, RoutePet } from './interfaces';
import RouteInfoWindow from './route-info-window';
import { formatStringDate } from 'kumba-utils/src/date';
import PetTypes from 'kumba-common/src/enums/pet-types';
import Button from 'kumba-components/src/components/Button';

interface RouteDirectionsProps {
  route: SlotRoute;
  onPetClicked: ({ slotId, appointmentId }) => void;
  onNavigationButtonClick: () => void;
}

function getPetIcon({ arePetsToVisit, pets }) {
  const isMultiplePets = pets.length > 1;

  if (isMultiplePets) {
    return CatDogIcon;
  } else {
    if (pets[0].petType === PetTypes.DOG) {
      return arePetsToVisit ? DogIconRed : DogIconBlue;
    } else {
      return arePetsToVisit ? CatIconRed : CatIconBlue;
    }
  }
}

function formatRouteWaypoints(
  appointments: RouteAppointment[],
  slotId,
  onPetClicked,
  onNavigationButtonClick
) {
  // flatten appointments by pet owner
  const wayPoints: {
    slotId: string;
    petOwnerId;
    pets: RoutePet[];
    lat: number;
    lng: number;
    address: string;
    icon?: string;
    petOwnerName: string;
    petOwnerPhone: string;
    label: string;
  }[] = [];
  for (const currAppointment of appointments) {
    let petOwnerWaypoint = wayPoints.find(
      wp => wp.petOwnerId === currAppointment.petOwnerId
    );
    if (!petOwnerWaypoint) {
      wayPoints.push({
        slotId,
        pets: [],
        petOwnerId: currAppointment.petOwnerId,
        petOwnerName: currAppointment.petOwnerName,
        lat: currAppointment.addressLat,
        lng: currAppointment.addressLng,
        address: currAppointment.address,
        petOwnerPhone: currAppointment.petOwnerPhone,
        label: formatStringDate({
          stringDate:
            currAppointment.actualStartDate || currAppointment.plannedStartDate,
          format: 'HH:mm',
        }),
      });

      // Now its there... :)
      petOwnerWaypoint = wayPoints.find(
        wp => wp.petOwnerId === currAppointment.petOwnerId
      );
    }

    (petOwnerWaypoint as any).pets.push({
      id: currAppointment.petId,
      name: currAppointment.petName,
      profilePictureUrl: currAppointment.profilePictureUrl,
      appointmentId: currAppointment.id,
      startDate:
        currAppointment.actualStartDate || currAppointment.plannedStartDate,
      petType: currAppointment.petType,
      endDate: formatStringDate({
        stringDate:
          currAppointment.actualEndDate || currAppointment.plannedEndDate,
        format: 'HH:mm',
      }),
      isfirstTimeVisit: currAppointment.isFirstTimeVisit,
      isVisited: !!currAppointment.actualEndDate,
    });
  }

  return wayPoints.map(wp => {
    const arePetsToVisit = !!wp.pets.find(p => !p.isVisited);

    return {
      ...wp,
      icon: getPetIcon({ arePetsToVisit, pets: wp.pets }),
      infoWindowContent: (
        <RouteInfoWindow
          onPetClicked={onPetClicked}
          pets={wp.pets}
          arePetsToVisit={arePetsToVisit}
          slotId={wp.slotId}
          petOwnerName={wp.petOwnerName}
          petOwnerPhone={wp.petOwnerPhone}
          address={wp.address}
          lat={wp.lat}
          lng={wp.lng}
          onNavigationButtonClick={onNavigationButtonClick}
        />
      ),
    };
  });
}

function RouteDirections({
  route,
  onPetClicked,
  onNavigationButtonClick,
}: RouteDirectionsProps) {
  const destination = {
    lat: route.destinationLat || route.startFromLat,
    lng: route.destinationLng || route.startFromLng,
  };

  const directions = {
    destination,
    origin: {
      lat: route.startFromLat,
      lng: route.startFromLng,
      label: (route.actualStartDate || route.startDate).split(',')[1].trim(),
    },
    waypoints: formatRouteWaypoints(
      route.appointments,
      route.id,
      onPetClicked,
      onNavigationButtonClick
    ),
    travelMode: route.transportationMethod || TransportationMethods.DRIVING,
    onDirectionsChanged: val => console.log(val),
  };
  const directionUrl = getDirectionsUrl(directions);
  return (
    <div className="directions">
      {!!route.appointments.length && (
        <GoogleMap
          center={{
            lat: route.appointments[0].addressLat,
            lng: route.appointments[0].addressLng,
          }}
          directions={directions}
        />
      )}
      <Button onClick={() => window.open(directionUrl, '_blank')}>
        {getText('route.directionLink')} &nbsp;
        <DirectionsIcon />
      </Button>
    </div>
  );
}

export default RouteDirections;
