import * as React from 'react';
import AlertBox, {
  AlertBoxVariants,
} from 'kumba-components/src/components/AlertBox';
import { getText } from 'kumba-common/src/libs/i18n';
import Input from 'kumba-components/src/components/Input';
import { InputTypes } from 'kumba-components/src/components/Form';
import FormHelperText from '@material-ui/core/FormHelperText';

interface RabiesFieldsProps {
  appointment: {
    pet: { chipNumber: string; petOwner: { user: { idNumber?: string } } };
  };
  idNumber: string;
  chipNumber: string;
  onInputChange: (inputName, value) => void;
}

const PET_REGISTY_TEMPLATE = `https://www.moag.gov.il/Pages/DogSearch.aspx#k=(bcsmspshvav=%22CHIP_NUMBER%22)`;

function RabiesFields({
  appointment,
  idNumber,
  chipNumber,
  onInputChange,
}: RabiesFieldsProps) {
  const hasMissingRabiesFields =
    !appointment.pet.chipNumber || !appointment.pet.petOwner.user.idNumber;
  return (
    <React.Fragment>
      {!!hasMissingRabiesFields && (
        <AlertBox message={getText('appointment.rabiesAlertBox')} />
      )}
      {!appointment.pet.petOwner.user.idNumber && (
        <Input
          name={'idNumber'}
          isRequired={true}
          value={idNumber}
          label={getText('idNumber')}
          inputType={InputTypes.Text}
          maxLength={9}
          helperText={getText('appointment.idNumberHelper')}
          onChange={(e, val) => onInputChange('idNumber', e.target.value)}
        />
      )}
      {!appointment.pet.chipNumber && (
        <Input
          name={'chipNumber'}
          value={chipNumber}
          isRequired={true}
          label={getText('appointment.chipNumber')}
          inputType={InputTypes.Text}
          maxLength={15}
          helperText={
            <FormHelperText>
              Pro Tip: Use{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.ar.lens"
              >
                Google Lens
              </a>{' '}
              to easily capture the chip number
            </FormHelperText>
          }
          onChange={(e, val) => onInputChange('chipNumber', e.target.value)}
        />
      )}
      {!!appointment.pet.chipNumber && (
        <label>{`${getText('appointment.chipNumber')}: ${
          appointment.pet.chipNumber
        }`}</label>
      )}
      {!!chipNumber && chipNumber.length === 15 && (
        <AlertBox variant={AlertBoxVariants.Info}>
          <React.Fragment>
            <div>{getText('appointment.chipNumberVerification')}</div>
            <a
              href={PET_REGISTY_TEMPLATE.replace('CHIP_NUMBER', chipNumber)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Pet Registry
            </a>
          </React.Fragment>
        </AlertBox>
      )}
    </React.Fragment>
  );
}

export default RabiesFields;
