export const getVetProfile = `query getVetProfile($where: VetWhereUniqueInput!) {
  vet(where: $where) {
    vetId
    license
    tShirtSize
    user {
      email
      firstName
      lastName
      phone
      address
      addressLat
      addressLng
      userId
      profilePictureUrl
    }
  }
}
`;

export const getVetTimeSlots = `query getVetTimeSlots($vetId: String!) {
  vet(where: { vetId: $vetId }) {
    vetId
    timeSlots(orderBy: endDate_ASC, last: 50) {
      id
      isOpenForScheduling
      transportationMethod
      maxVisits
      startDate
      startFromAddress
      startFromLat
      startFromLng
      destinationAddress
      destinationLat
      destinationLng
      actualStartDate
      actualEndDate
      name
      endDate
      actualEndDate
      vetComment
      circleAreas {
        id
        radius
        centerLat
        centerLng
      }
      # Need to explicitly get the non-deleted ones. Known issue
      appointments(where: { isDeleted: false }) {
        id
      }
    }
  }
}
`;

export const getTimeSlot = `query getTimeSlot($slotId: ID!) {
  timeSlot(where: { id: $slotId }) {
    id
    maxVisits
    name
    startDate
    endDate
    vetComment
    startDate
    endDate
    transportationMethod
    vetComment
    isOpenForScheduling
    startFromAddress
    startFromLat
    startFromLng
    destinationAddress
    destinationLat
    destinationLng
    slotType
    circleAreas {
      id
      radius
      centerLat
      centerLng
    }
    polygonAreas {
      id
      paths
    }
    # Need to explicitly get the non-deleted ones. Known issue
    appointments(where: { isDeleted: false }) {
      id
    }
  }
}
`;

export const getVetSlotDetails = `query getVetSlotDetails(
  $vetId:String!
) {
  user(where:{userId: $vetId}) {
    address
    addressLat
    addressLng
  }
}
`;

export const getVetAppointments = `query getVetAppointments($vetId: String!) {
  vet(where: { vetId: $vetId }) {
    vetId
    timeSlots {
      id
      startDate
      endDate
      # Need to explicitly get the non-deleted ones. Known issue
      appointments(where: { isDeleted: false }) {
        id
        pet {
          id
          name
        }
      }
    }
  }
}
`;

export const getRoute = `query getRoute($routeId: ID!) {
	timeSlot(where: { id: $routeId }) {
		id
		name
		startDate
		endDate
		actualStartDate
		actualEndDate
		startFromAddress
		startFromLat
		startFromLng
		destinationAddress
		destinationLat
		destinationLng
		transportationMethod
		isOpenForScheduling
		vet {
      id
			user {
        id
				firstName
				lastName
			}
		}
		appointments(orderBy: plannedStartDate_ASC) {
			id
			plannedStartDate
			actualStartDate
			plannedEndDate
			actualEndDate
			pet {
				id
				name
				petType
				profilePictureUrl
				birthDate
				gender
				temperament
				weight
				petServices {
					id
					startDate
					petProducts {
						id
						remainingQuantity
		        serviceProduct {
              id
							product {
								id
								titleId
								productType
								usageFrequencyDays
								petType
							}
						}
						remainingQuantity
						appoinmentPetProducts {
							id
							appointment {
                id
								actualStartDate
							}
						}
					}
					service {
            id
						titleId
						durationDays
					}
				}
				# Vaccines given out of subscription
				appointments {
          id
          actualStartDate
					appointmentPurchases(where: { product: { productType: VACCINE } }) {
            id
						product {
							id
							titleId
						}
					}
				}
				petOwner {
          id
					petOwnerId
					apartmentNumber
					user {
            id
						firstName
						lastName
						addressLat
						addressLng
						address
						phone
						timezone
					}
				}
			}
		}
	}
}
`;

export const getAppointment = `query getAppointment($appointmentId: ID!) {
  appointment(where: { id: $appointmentId }) {
    id
    actualStartDate
    actualEndDate
    petOwnerComment
    vetComment
    adminComment
    isNoShowStarted
    timeSlot {
      id
      actualStartDate
      actualEndDate
      vet {
        id
        vetId
      }
    }
    pet {
      id
      name
      petType
      chipNumber
      weight
      birthDate
      gender
      profilePictureUrl
      documentGroupUrl
      temperament
      isCastrated
      isSpayed
      petServices(orderBy: startDate_DESC) {
        id
        startDate
        petProducts {
          id
          remainingQuantity
		      serviceProduct {
            id
            product {
              id
              titleId
              productType
              usageFrequencyDays
              petType
              price
            }
          }
          remainingQuantity
          appoinmentPetProducts {
            id
            appointment {
              id
              actualStartDate
            }
          }
        }
        service {
          id
          titleId
          durationDays
        }
      }
      appointments {
        id
        actualStartDate
      }
      petOwner {
        id
        apartmentNumber
        floorNumber
        apartmentDetails
        user {
          id
          userId
          firstName
          lastName
          idNumber
          address
          phone
        }
      }
    }
  }
}
`;

export const getVaccines = `query getVaccines {
  products(where:{
    productType:VACCINE
  }) {
    id
    titleId
  }
}`;

export const getAllProducts = `query getAllProducts {
	products(where: { isActive: true }) {
		id
		petType
		productType
		titleId
		price
		isActive
		activityTimezones
	}
}
`;

export const getProducts = `query getProducts {
  products(where:{
    productType:MEDICATION
  }) {
    id
    titleId
    petType
  }
}`;

export const getVetsFeedbackDetailsBySlotId = `query getVetsFeedbackDetailsBySlotId($slotId: ID!) {
  timeSlot(where: { id: $slotId }) {
    id
    # Need to explicitly get the non-deleted ones. Known issue
    appointments(where: { isDeleted: false }) {
      pet {
        petOwner {
          user {
            userId
            phone
          }
        }
      }
    }
    vet {
      vetId
      user {
        userId
        lastName
        firstName
      }
    }
  }
}
`;

export const getInventory = `query getInventory($vetId: String!) {
  vet(where: { vetId: $vetId }) {
    id
    vetId
    productInventory {
      id
      remainingQuantity
      product {
        id
        titleId
        minInventoryQuantity
      }
    }
  }
}`;

export const getInventoryProducts = `query getInventoryProducts {
  products(where:{productType_not_in:[VISIT,MEDICAL_TREATMENT]}) {
    id
    productType
    titleId
    price
  }
}`;

export const getPetsMedicalConsultations = `query getPetsMedicalConsultations(
	$petName: String
	$ownerFirstName: String
	$ownerLastName: String
) {
	pets(
		last: 5
		where: {
			name: $petName
			petOwner: {
				user: { firstName: $ownerFirstName, lastName: $ownerLastName }
			}
		}
	) {
		id
		name
		petOwner {
			user {
				userId
				firstName
				lastName
			}
		}
	}
}
`;

export const getVetActivity = `query getVetActivity(
  $startDate: DateTime!
  $endDate: DateTime!
  $vetId: String!
) {
  appointments(
    first: 300
    where: {
      actualEndDate_gt: $startDate
      actualEndDate_lt: $endDate
      timeSlot: { vet: { vetId: $vetId } }
    }
  ) {
    id
    appointmentPurchases {
      id
			quantity
      product {
        id
        titleId
        price
        vetMargin
      }
    }
    appointmentPetProducts {
      id
			quantity
      petProduct {
        id
        serviceProduct {
          id
          product {
            id
            titleId
            price
            vetMargin
          }
        }
      }
    }   
  }
}
`;
