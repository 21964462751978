import {
  addUnitsToDate,
  getCurrentIsoDate,
  formatStringDate,
} from 'kumba-utils/src/date';
import { isProd } from 'kumba-common/src/libs/env';
import TimeUnits from 'kumba-utils/src/enums/time-units';
import { dashboardInputDefinition } from './input-definitions';
import { getText, getNestedText } from 'kumba-common/src/libs/i18n';
import { ITableRow } from 'kumba-components/src/components/Table';
import { IVetActivityData, IActivityFormatted } from './interfaces';
import { addCurrencyToPrice } from 'kumba-common/src/services/localization';

export const DEFAULT_START_DATE = isProd()
  ? addUnitsToDate({
      unit: TimeUnits.DAYS,
      amount: -30,
      date: getCurrentIsoDate(),
      format: 'YYYY-MM-DD',
    })
  : '2019-09-22';
export const DEFAULT_END_DATE = formatStringDate({
  stringDate: getCurrentIsoDate(),
  format: 'YYYY-MM-DD',
});

const PAYMENT_PER_VISIT = 70;

export function updateFilterWithInitialValues({ startDate, endDate }) {
  return dashboardInputDefinition.map(currInputDefinition => {
    let initialValue = currInputDefinition.initialValue;

    if (currInputDefinition.name === 'startDate') {
      initialValue = startDate || DEFAULT_START_DATE;
    } else if (currInputDefinition.name === 'endDate') {
      initialValue = endDate || DEFAULT_END_DATE;
    }
    return { ...currInputDefinition, initialValue };
  });
}

function increaseProductQuantity({
  currProductId,
  activitiesFormatted,
  currQuantity,
  titleId,
  price,
  vetMargin,
}) {
  if (!activitiesFormatted[currProductId]) {
    activitiesFormatted[currProductId] = {
      titleId,
      price,
      vetMargin,
      quantity: 0,
    };
  }

  activitiesFormatted[currProductId].quantity += currQuantity;
}

export function formatActivityData({
  vetActivityData,
}: {
  vetActivityData: IVetActivityData;
}): IActivityFormatted {
  const activitiesFormatted: IActivityFormatted = {};

  vetActivityData.appointments.forEach(currAppointment => {
    currAppointment.appointmentPurchases.forEach(currAppointmentPurchase => {
      increaseProductQuantity({
        currProductId: currAppointmentPurchase.product.id,
        currQuantity: currAppointmentPurchase.quantity || 0,
        activitiesFormatted,
        titleId: currAppointmentPurchase.product.titleId,
        price: currAppointmentPurchase.product.price,
        vetMargin: currAppointmentPurchase.product.vetMargin,
      });
    });
    currAppointment.appointmentPetProducts.forEach(
      currAppointmentPetProduct => {
        increaseProductQuantity({
          currProductId:
            currAppointmentPetProduct.petProduct.serviceProduct.product.id,
          titleId:
            currAppointmentPetProduct.petProduct.serviceProduct.product.titleId,
          currQuantity: currAppointmentPetProduct.quantity || 0,
          activitiesFormatted,
          vetMargin:
            currAppointmentPetProduct.petProduct.serviceProduct.product
              .vetMargin,
          price:
            currAppointmentPetProduct.petProduct.serviceProduct.product.price,
        });
      }
    );
  });

  return activitiesFormatted;
}

export function prepareActivityTableRows({
  vetActivityFormatted,
  numAppointments,
}: {
  vetActivityFormatted: IActivityFormatted;
  numAppointments: number;
}): ITableRow[] {
  const productRows: ITableRow[] = [];
  Object.keys(vetActivityFormatted).forEach(currProductId => {
    const titleId = vetActivityFormatted[currProductId].titleId;
    const vetMargin = addCurrencyToPrice({
      price: Number(
        (
          vetActivityFormatted[currProductId].price *
          vetActivityFormatted[currProductId].vetMargin
        ).toFixed(1)
      ),
    });
    productRows.push({
      id: currProductId,
      content: [
        {
          value: getNestedText(`products.${titleId}`) || titleId,
        },
        {
          value: vetActivityFormatted[currProductId].quantity || 0,
        },
        {
          value: addCurrencyToPrice({
            price: vetActivityFormatted[currProductId].price || 0,
          }),
        },
        {
          value: vetMargin,
        },
      ],
    });
  });

  return [
    {
      id: 'CUSTOM_ID',
      content: [
        {
          value: getText('vetVisit'),
        },
        {
          value: numAppointments,
        },
        { value: addCurrencyToPrice({ price: PAYMENT_PER_VISIT }) },
        { value: addCurrencyToPrice({ price: PAYMENT_PER_VISIT }) },
      ],
    },
  ].concat(productRows);
}

export function getTotalPayment({
  numAppointments,
  vetActivityFormatted,
}: {
  numAppointments: number;
  vetActivityFormatted: IActivityFormatted;
}) {
  let totalPayment = numAppointments * PAYMENT_PER_VISIT;
  Object.keys(vetActivityFormatted).forEach(currProductId => {
    const currVetActivity = vetActivityFormatted[currProductId];
    totalPayment +=
      currVetActivity.quantity *
      currVetActivity.price *
      (currVetActivity.vetMargin || 0);
  });

  return Math.round(totalPayment);
}
