import { VET_UPDATE } from './../constants/action-types';
import initialState from './initial-state';

export default function appReducer(state = initialState.vet, action) {
  switch (action.type) {
    case VET_UPDATE:
      return {
        ...state,
        vet: action.vet,
      };
    default:
      return state;
  }
}
