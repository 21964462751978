import { VET_UPDATE } from './../constants/action-types';

export function vetUpdate({
  firstName,
  lastName,
  phone,
  address,
  license,
  tShirtSize,
  profilePictureUrl,
}) {
  return {
    firstName,
    lastName,
    phone,
    license,
    tShirtSize,
    profilePictureUrl,
    address: address.name,
    addressLat: address.addressLat,
    addressLng: address.addressLng,
    type: VET_UPDATE,
  };
}
