import {
  formatStringDate,
  HOUR_IN_TIMESTAMP,
  getIsoDate,
  isLaterThan,
} from 'kumba-utils/src/date';
import { User } from 'kumba-serverless/src/generated/dev/prisma-client';
import { get } from 'lodash';
import uuid = require('uuid');

const APPOINTMENT_AFFECTED_FIELDS = ['area', 'date', 'maxVisits'];
const CLOSED_FOR_SCHEDULING_AFFECTED_FIELDS = [
  'transportationMethod',
  'startFromAddress',
  'destinationAddress',
];

function shouldDisableField({
  hasAppointments,
  inputDefinitionName,
  isClosedForScheduling,
}) {
  return (
    (hasAppointments &&
      APPOINTMENT_AFFECTED_FIELDS.includes(inputDefinitionName)) ||
    (isClosedForScheduling &&
      CLOSED_FOR_SCHEDULING_AFFECTED_FIELDS.includes(inputDefinitionName))
  );
}

export function updateWithValues({
  inputDefinitions,
  slot,
  shouldDisableFields,
}) {
  const hasAppointments = !!get(slot, 'appointments.length');
  const isClosedForScheduling = !slot.isOpenForScheduling;
  return inputDefinitions.map(inputDefinition => {
    let initialValue;

    switch (inputDefinition.name) {
      case 'area':
        initialValue = {
          circles: slot.circleAreas.map(ca => {
            return {
              id: ca.id,
              center: {
                lat: ca.centerLat,
                lng: ca.centerLng,
              },
              radius: ca.radius,
            };
          }),
          polygons: slot.polygonAreas.map(polygon => {
            return {
              id: polygon.id,
              paths: JSON.parse(polygon.paths),
            };
          }),
        };
        break;

      case 'date':
        initialValue = formatStringDate({
          stringDate: slot.startDate,
          format: 'YYYY-MM-DD',
        });
        break;
      case 'startTime':
        initialValue = formatStringDate({
          stringDate: slot.startDate,
          format: 'HH:mm',
        });
        break;
      case 'endTime':
        initialValue = formatStringDate({
          stringDate: slot.endDate,
          format: 'HH:mm',
        });
        break;

      default:
        initialValue = slot[inputDefinition.name] || null;
        break;
    }
    const mergedResult = {
      ...inputDefinition,
      initialValue,
      isDisabled:
        !!shouldDisableFields &&
        shouldDisableField({
          hasAppointments,
          isClosedForScheduling,
          inputDefinitionName: inputDefinition.name,
        }),
    };

    return mergedResult;
  });
}

export function updateWithInitialValues({
  inputDefinitions,
  vet,
}: {
  inputDefinitions: any;
  vet: User;
}) {
  return inputDefinitions.map(inputDefinition => {
    let initialValue;

    switch (inputDefinition.name) {
      case 'startFromAddress':
      case 'destinationAddress':
        initialValue = {
          name: vet.address,
          addressLat: vet.addressLat,
          addressLng: vet.addressLng,
        };
        break;
      case 'area': {
        const POLYGON_OFFSET = 0.02;
        const { addressLat = 0, addressLng = 0 } = vet;
        initialValue = {
          polygons: [
            {
              id: `polygon-${uuid()}`,
              paths: [
                {
                  lat: addressLat + POLYGON_OFFSET,
                  lng: addressLng + POLYGON_OFFSET,
                },
                {
                  lat: addressLat - POLYGON_OFFSET,
                  lng: addressLng + POLYGON_OFFSET,
                },
                {
                  lat: addressLat - POLYGON_OFFSET,
                  lng: addressLng - POLYGON_OFFSET,
                },
                {
                  lat: addressLat + POLYGON_OFFSET,
                  lng: addressLng - POLYGON_OFFSET,
                },
              ],
            },
          ],
          center: {
            lat: vet.addressLat,
            lng: vet.addressLng,
          },
        };
        break;
      }
      default:
        initialValue = inputDefinition.initialValue;
        break;
    }
    const mergedResult = {
      ...inputDefinition,
      initialValue,
    };

    return mergedResult;
  });
}

export function formatValues(values) {
  const startFromAddress = values.startFromAddress || {};
  const destinationAddress = values.destinationAddress || {};
  return {
    ...values,
    startFromAddress: startFromAddress.name,
    startFromLat: startFromAddress.addressLat,
    startFromLng: startFromAddress.addressLng,
    destinationAddress: destinationAddress.name,
    destinationLat: destinationAddress.addressLat,
    destinationLng: destinationAddress.addressLng,
    startDate: getIsoDate({ stringDate: `${values.date}T${values.startTime}` }),
    endDate: getIsoDate({ stringDate: `${values.date}T${values.endTime}` }),
  };
}

export function isTimeSufficient({ startTimestamp, endTimestamp, maxVisits }) {
  // No more than 3 patients per hour in time slot
  const numHours = Math.ceil(
    (endTimestamp - startTimestamp) / HOUR_IN_TIMESTAMP
  );

  return numHours * 3 >= maxVisits;
}

export function isNewSlotContainedInOldSlot({ oldTimeSlot, newTimeSlot }) {
  return (
    isLaterThan(newTimeSlot.startDate, oldTimeSlot.startDate) &&
    isLaterThan(oldTimeSlot.endDate, newTimeSlot.endDate)
  );
}
