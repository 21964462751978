import { all } from 'redux-saga/effects';
import authSaga from './auth-saga';
import vetSaga from './vet-saga';
import slotSaga from './slot-saga';
import routeSaga from './route-saga';
import appointmentSaga from './appointment-saga';
import inventorySaga from './inventory-saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    vetSaga(),
    slotSaga(),
    routeSaga(),
    appointmentSaga(),
    inventorySaga(),
  ]);
}
