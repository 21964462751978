import * as React from 'react';
import styled from 'styled-components';
import { formatProducts, formatChangedProducts } from './helpers';
import { Product } from 'kumba-serverless/src/generated/dev/prisma-client';
import Accordion from 'kumba-components/src/components/Accordion';
import { getText } from 'kumba-common/src/libs/i18n';
import { PetType } from 'kumba-serverless/src/generated/graphqlgen';
import NumberPicker from 'kumba-components/src/components/NumberPicker';
import AppointmentProduct from 'kumba-common/src/interfaces/appointment-product';
import Input from 'kumba-components/src/components/Input';

const ProductPickerStyledComponent = styled.div`
  .accordion-content {
    display: flex;
    flex-direction: column;
  }
`;

interface ProductPickerProps {
  products: Product[];
  selectedProducts: AppointmentProduct[];
  petType: PetType;
  onProductsChanged: (newProducts: AppointmentProduct[]) => void;
  productIdsInSubscription: string[];
  onRabiesSelected: (isRabiesQuantity: boolean) => void;
  isRabiesGiven: boolean;
  isNonExpiredSubscription: boolean;
  disabled?: boolean;
}

function search(find, within) {
  find = find.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&');
  var phrase = /"[^"]+"/g;
  var phrases = find.match(phrase) || [];
  phrases = phrases.map(function(s) {
    return s.replace(/"/g, '');
  });
  var words = find.replace(phrase, '').split(/\s+/);
  var terms = words.concat(phrases);
  var searchExpression = new RegExp(
    '(?=.*' + terms.join(')(?=.*') + ').+',
    'i'
  );
  return searchExpression.test(within);
  // return product.label.toLowerCase().indexOf(searchedProduct.toLowerCase())>=0;
}

function ProductPicker({
  products,
  selectedProducts,
  petType,
  onProductsChanged,
  productIdsInSubscription,
  onRabiesSelected,
  isRabiesGiven,
  isNonExpiredSubscription,
  disabled,
}: ProductPickerProps) {
  const formattedProducts = formatProducts({
    products,
    selectedProducts,
    petType,
    productIdsInSubscription,
    isRabiesGiven,
    isNonExpiredSubscription,
  });
  const placeholder: string = 'Search...';
  const isRequired: boolean = false;
  const [searchedProduct, setSearchedProduct] = React.useState('');

  const filteredProducts = formattedProducts.filter(product => {
    return search(searchedProduct, product.label);
  });

  return (
    <ProductPickerStyledComponent>
      {!!formattedProducts.length && (
        <Accordion
          header={getText('appointment.products')}
          subheader={
            isNonExpiredSubscription
              ? getText('appointment.productsDiscount')
              : undefined
          }
        >
          <div className="search-input-container">
            <Input
              customClass="search-input"
              name="search-product-input"
              isRequired={isRequired}
              autoComplete="none"
              placeholder={placeholder}
              value={searchedProduct}
              onChange={(e, val) => setSearchedProduct(val)}
            />
          </div>
          {filteredProducts.map(currProduct => (
            <NumberPicker
              key={currProduct.productId}
              value={currProduct.quantity}
              label={currProduct.label}
              disabled={disabled}
              onChange={newQuantity => {
                onProductsChanged(
                  formatChangedProducts({
                    selectedProducts,
                    quantity: newQuantity,
                    productId: currProduct.productId,
                  })
                );
                currProduct.label.toLowerCase().includes('rabies') &&
                  onRabiesSelected(newQuantity > 0);
              }}
            />
          ))}
        </Accordion>
      )}
    </ProductPickerStyledComponent>
  );
}

export default ProductPicker;
