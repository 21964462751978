import {
  AUTH_SIGN_UP,
  AUTH_INIT,
  AUTH_LOGOUT,
  AUTH_LOGIN,
  AUTH_FORGOT_PASSWORD,
} from './../constants/action-types';
import KumbaRoles from 'kumba-common/src/enums/kumba-roles';

export function authSignup({ email, password }) {
  return {
    password,
    email: email.toLowerCase(),
    type: AUTH_SIGN_UP,
    kumbaRole: KumbaRoles.VET,
  };
}

export function authForgotPassword({ email, code, password }) {
  return {
    code,
    email: email.toLowerCase(),
    new_password: password,
    type: AUTH_FORGOT_PASSWORD,
  };
}

export function authLogin({ email, password }) {
  return {
    password,
    email: email.toLowerCase(),
    type: AUTH_LOGIN,
    kumbaRole: KumbaRoles.VET,
  };
}

export function authInit() {
  return {
    type: AUTH_INIT,
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}
