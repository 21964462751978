export const SIGNUP = 'signup';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const PROFILE = 'profile';
export const SLOTS = 'slots';
export const SLOT_CREATE = 'slot-create';
export const SLOT_EDIT = 'slots/:slotId';
export const ROUTE = 'slots/:slotId/route';
export const ROUTES = 'routes';
export const ACTIVITY = 'activity';
export const MEDICAL_CONSULTATIONS = 'medical-consultations';
export const APPOINTMENT = 'slots/:slotId/appointments/:appointmentId';
export const INVENTORY = 'inventory';

// Routes from pets
export const APPOINTMENT_CREATE = 'appointment/create';
