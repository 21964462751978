import {
  InputTypes,
  InputDefinition,
} from 'kumba-components/src/components/Form';
import { getText } from 'kumba-common/src/libs/i18n';
import KumbaApps from 'kumba-common/src/enums/kumba-apps';

export const dashboardInputDefinition: InputDefinition[] = [
  {
    name: 'startDate',
    inputType: InputTypes.Date,
    isRequired: true,
    showTime: false,
    label: getText('startDate', {}, false, KumbaApps.KUMBA_VETS),
  },
  {
    name: 'endDate',
    inputType: InputTypes.Date,
    isRequired: true,
    showTime: false,
    label: getText('endDate', {}, false, KumbaApps.KUMBA_VETS),
  },
];
