import * as React from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import initialState from '../../reducers/initial-state';
import Spinner from 'kumba-components/src/components/Spinner';
import { getInventory } from '../../graphql-custom/queries';
import InventoryStyledComponent from './styled-component';
import InventoryTable from './inventory-table';
import { get } from 'lodash';
import Text, { TextTypes } from 'kumba-components/src/components/Text';
import { getText } from 'kumba-common/src/libs/i18n';
import { ButtonAdd } from 'kumba-components/src/components/Button';
import AddSupplyModal from './add-supply-modal';
import { showMessage } from '../../actions/app-actions';
import { sortProductInventory } from './helpers';

interface InventoryProps {
  vetId: string;
  isAdmin: boolean;
  showMessage: any;
}

function Inventory({ vetId, isAdmin, showMessage }: InventoryProps) {
  const { data, error, loading, refetch } = useQuery(gql(getInventory), {
    variables: { vetId },
  });
  const [isSupplyModalOpen, setIsSupplyModalOpen] = React.useState(false);

  if (loading) {
    return <Spinner />;
  } else if (error) {
    console.error(`error`, error);
    return <div>Something went wrong...</div>;
  } else {
    return (
      <InventoryStyledComponent className="kumba-inventory">
        <Text textType={TextTypes.H2}>{getText('inventory.title')}</Text>
        {get(data, 'vet.productInventory.length') ? (
          <InventoryTable
            inventoryData={data.vet.productInventory.sort(sortProductInventory)}
            onQuantityUpdated={() => refetch()}
          ></InventoryTable>
        ) : (
          <Text textType={TextTypes.H3}>{getText('inventory.noData')}</Text>
        )}

        {!!isAdmin && (
          <ButtonAdd
            text={getText('inventory.add')}
            onClick={() => setIsSupplyModalOpen(true)}
          />
        )}
        {!!isSupplyModalOpen && (
          <AddSupplyModal
            onClose={() => {
              setIsSupplyModalOpen(false);
              refetch().catch(e => console.error(e));
            }}
          />
        )}
      </InventoryStyledComponent>
    );
  }
}
const mapStateToProps = (state = initialState) => {
  return {
    vetId: state.auth.userId,
    isAdmin: state.auth.isAdmin,
  };
};
export default connect(
  mapStateToProps,
  { showMessage }
)(Inventory);
