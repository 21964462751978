import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import UnauthenticatedRoute from './components/unauthenticated-route';
import AuthenticatedRoute from './components/authenticated-route';

import Signup from './containers/signup';
import Login from './containers/login';
import Profile from './containers/profile';
import Slots from './containers/slots';
import SlotCreate from './containers/slot-create';
import SlotRoute from './containers/route';
import ForgotPassword from './containers/forgot-password';
import Appointment from './containers/appointment';
import MedicalConsultations from './containers/medical-consultations';
import Activity from './containers/activity';
import NotFound from './containers/not-found';
import {
  SLOTS,
  SLOT_CREATE,
  SLOT_EDIT,
  ROUTE,
  APPOINTMENT,
  FORGOT_PASSWORD,
  INVENTORY,
  MEDICAL_CONSULTATIONS,
  ACTIVITY,
} from './constants/routes';
import Inventory from './containers/inventory';
import { trackPageView } from 'kumba-common/src/libs/analytics';
import { isProd } from 'kumba-common/src/libs/env';

const VetRoutes = ({ childProps }) => {
  React.useEffect(() => {
    trackPageView({ path: location.pathname });
  }, [location]);
  const isHybridApp =
    !!(window as any).KUMBA_CONFIG &&
    !!(window as any).KUMBA_CONFIG.isHybridApp;

  return (
    <Switch>
      <AuthenticatedRoute
        path="/"
        exact
        component={isProd() ? Activity : MedicalConsultations}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={Signup}
        props={childProps}
      />
      <UnauthenticatedRoute
        path={`/${FORGOT_PASSWORD}`}
        exact
        component={ForgotPassword}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/profile"
        exact
        component={Profile}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${SLOTS}`}
        exact
        component={Slots}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${SLOT_CREATE}`}
        exact
        component={SlotCreate}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${SLOT_EDIT}`}
        exact
        component={SlotCreate}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${ROUTE}`}
        exact
        component={SlotRoute}
        props={childProps}
      />

      <AuthenticatedRoute
        path={`/${APPOINTMENT}`}
        exact
        component={Appointment}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${INVENTORY}`}
        exact
        component={Inventory}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${MEDICAL_CONSULTATIONS}`}
        exact
        component={MedicalConsultations}
        props={childProps}
      />
      <AuthenticatedRoute
        path={`/${ACTIVITY}`}
        exact
        component={Activity}
        props={childProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={isHybridApp ? Slots : NotFound} />
    </Switch>
  );
};

export default VetRoutes;
