import { mutate } from 'kumba-common/src/libs/apollo';
import { upsertMedicalConsultationMutation } from '../../graphql-custom/mutations';

export interface IMedicalConsultationPet {
  id: string;
  name: string;
  petOwner: {
    user: {
      userId: string;
      firstName: string;
      lastName: string;
    };
  };
}

export enum ConsultationPageModalIds {
  UPSERT_CONSULTATION,
}

export async function upsertMedicalConsultation({
  summary,
  consultationDate,
  petId,
  vetId,
  documentGroupUrl,
  consultationId = 'NON_EXISTING_ID',
}) {
  await mutate({
    mutation: upsertMedicalConsultationMutation,
    variables: {
      summary,
      consultationDate,
      petId,
      vetId,
      documentGroupUrl,
      consultationId,
    },
  });
}
