import * as React from 'react';
import * as queryString from 'query-string';
import { connect } from 'react-redux';

import Form from 'kumba-components/src/components/Form';
import ProfileStyledComponent from './styled-component';
import { vetUpdate } from '../../actions/vet-actions';
import profileInputDefinitions from './profile-input-definition';

import Spinner from 'kumba-components/src/components/Spinner';
import initialState from '../../reducers/initial-state';

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { getVetProfile } from '../../graphql-custom/queries';
import mockVet from './vet.mock';
import { getText } from 'kumba-common/src/libs/i18n';
import getDefaultErrorMessages from 'kumba-common/src/constants/error-messages';

interface ProfileProps {
  location: Location;
  vetUpdate: () => any;
  userId: string;
  profile: any;
}
interface ProfileState {
  profilePictureUrl: string;
}

class Profile extends React.Component<ProfileProps, ProfileState> {
  constructor(props) {
    super(props);

    this.state = {
      profilePictureUrl: '',
    };
  }

  updateWithInitialValues = (vet = mockVet) => {
    return this.profileInputDefinitions.map(input => {
      const initialValue = vet[input.name] || vet.user[input.name];

      return {
        ...input,
        initialValue,
      };
    });
  };

  profileInputDefinitions = profileInputDefinitions;

  render() {
    if (this.props.profile.loading) {
      return <Spinner />;
    } else if (this.props.profile.error) {
      console.error(this.props.profile.error);
      return <div>Something went wrong...</div>;
    } else {
      return (
        <ProfileStyledComponent>
          <Form
            formTitle={getText('profile.title')}
            formTitleImgSrc={this.props.profile.vet.user.profilePictureUrl}
            inputDefinitions={this.updateWithInitialValues(
              this.props.profile.vet
            )}
            onSubmit={this.props.vetUpdate}
            errorMessages={getDefaultErrorMessages()}
            submitLabel={getText('common.buttons.save')}
          />
        </ProfileStyledComponent>
      );
    }
  }
}

const mapStateToProps = (state = initialState) => {
  return {
    userId: state.auth.userId,
  };
};

export default compose(
  graphql(gql(getVetProfile), {
    name: 'profile',
    options: (props: any) => ({
      variables: { where: { vetId: props.id } },
      fetchPolicy: 'no-cache',
    }),
  }),
  connect(
    mapStateToProps,
    { vetUpdate }
  )
)(Profile);
