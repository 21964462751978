import { APP_SHOW_MESSAGE, APP_INIT } from './../constants/action-types';
import { ToastTypes } from 'kumba-components/src/components/Toast';
import { getTextDirection } from 'kumba-common/src/libs/i18n';

export function appInit() {
  return {
    type: APP_INIT,
    direction: getTextDirection(),
  };
}

export function showMessage({ message, messageType = ToastTypes.Success }) {
  return {
    message,
    messageType,
    type: APP_SHOW_MESSAGE,
  };
}
