import * as React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getText } from 'kumba-common/src/libs/i18n';
import Typography from '@material-ui/core/Typography/Typography';
import { formatStringDate, isFutureDate } from 'kumba-utils/src/date';
import SlotButtonSet from './slot-button-set';
import Accordion from 'kumba-components/src/components/Accordion';

import get = require('lodash/get');
import { formatSlotName } from './helpers';

interface TimeSlotListProps {
  slots: {
    id: string;
    name: string;
    isOpenForScheduling: boolean;
    startDate: string;
    endDate: string;
    actualEndDate;
  }[];
  history: any;
  onSlotDelete: (slotId) => void;
  onSlotDuplicate: (slotId) => void;
  isPastSlots: boolean;
}

const displayedFields = [
  'vetComment',
  'maxVisits',
  'repeatInterval',
  'startDate',
  'endDate',
  'startFromAddress',
  'destinationAddress',
  'transportationMethod',
];

function displayFieldValue({ slot, field = '' }) {
  if (field === 'transportationMethod') {
    return getText(`common.transportationMethod.${slot[field]}`);
  } else if (field.toLowerCase().includes('date')) {
    return formatStringDate({ stringDate: slot[field], showTime: true });
  } else {
    return slot[field];
  }
}

// Hide past slots with no appointments or ended routes
export function filterTimeSlots({ allSlots, isPastSlots }) {
  return allSlots.filter(currSlot => {
    const isStartDatePassed = !isFutureDate(currSlot.startDate);
    const isRouteOccured = currSlot.actualEndDate;
    const hasNoAppointments = !currSlot.appointments.length;

    if (isPastSlots) {
      return isRouteOccured || (isStartDatePassed && hasNoAppointments);
    } else {
      // Either later date, or past date with appointments
      return !isStartDatePassed || (!isRouteOccured && !hasNoAppointments);
    }
  });
}

function TimeSlotList({
  isPastSlots,
  history,
  onSlotDelete,
  onSlotDuplicate,
  slots,
}: TimeSlotListProps) {
  // Past slot
  const filteredSlots = filterTimeSlots({ isPastSlots, allSlots: slots });
  return (
    <div>
      {!filteredSlots.length && (
        <Typography variant="h5" className="no-slots">
          {getText('slots.noSlots')}
        </Typography>
      )}

      {filteredSlots.map((currSlot, i) => {
        const numPetsScheduled = get(currSlot, 'appointments.length') || 0;
        const { name, startDate, endDate } = currSlot;
        return (
          <Accordion
            key={currSlot.id}
            isDefaultExpanded={!i}
            header={formatSlotName({ startDate, endDate, name })}
            subheader={getText('slots.numPetsScheduled', {
              numPetsScheduled,
            })}
          >
            <List
              component="section"
              className="slot-list"
              dense={true}
              key={currSlot.id + i}
            >
              {Object.keys(currSlot).map(
                (field, index) =>
                  currSlot[field] &&
                  displayedFields.includes(field) && (
                    <ListItem key={currSlot.id + field + index}>
                      <span>
                        <b>- {getText(`slots.${field}`)}:&nbsp;</b>
                      </span>
                      <span>
                        {displayFieldValue({
                          field,
                          slot: currSlot,
                        })}
                      </span>
                    </ListItem>
                  )
              )}
              <hr />
              <SlotButtonSet
                history={history}
                onSlotDelete={onSlotDelete}
                onSlotDuplicate={onSlotDuplicate}
                isEditable={!isPastSlots}
                slot={currSlot}
              />
            </List>
          </Accordion>
        );
      })}
    </div>
  );
}

export default TimeSlotList;
