// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://cefdge2yyvbnjcx4re2lwcr7mq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dhduatebnjf4ve4fcdzlabjaou"
};


export default awsmobile;
