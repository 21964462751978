import styled from 'styled-components';

const loginStyledComponent = styled.div`
  .forgot-password {
    text-align: center;
    button {
      transform: translate(0, -50px);
      margin-right: 0;
    }
  }
  form .button-wrapper {
    justify-content: flex-end;
  }
`;

export default loginStyledComponent;
