import { createMuiTheme } from '@material-ui/core/styles';
import {
  PRIMARY,
  CONTRAST_TEXT,
  CONTRAST_TEXT_LIGHT,
} from 'kumba-common/src/constants/colors.constant';
export function getTheme({ direction }) {
  return createMuiTheme({
    direction: direction || 'ltr',
    palette: {
      primary: {
        light: CONTRAST_TEXT_LIGHT,
        main: PRIMARY,
        dark: PRIMARY,
        contrastText: CONTRAST_TEXT,
      },
      secondary: {
        light: '#ff7961',
        main: '#f7af60',
        dark: '#f7af60',
        contrastText: CONTRAST_TEXT_LIGHT,
      },
    },
  });
}
