import {
  AUTH_SIGN_UP,
  AUTH_SET,
  AUTH_LOGIN_SUCCESS,
  AUTH_INIT,
  AUTH_LOGOUT,
  AUTH_LOGIN,
  APP_FAILURE,
  APP_HIDE_SPINNER,
  APP_SHOW_SPINNER,
  AUTH_FORGOT_PASSWORD,
  APP_SHOW_MESSAGE,
  AUTH_FORGOT_PASSWORD_SUCCESS,
} from './../constants/action-types';
import { takeEvery, put } from 'redux-saga/effects';
import {
  signup,
  signIn,
  signOut,
  getUserDetails,
  forgotPasswordSubmit,
  forgotPassword,
  isAdmin,
} from 'kumba-common/src/libs/auth';
import { getText } from 'kumba-common/src/libs/i18n';
import { mutate } from 'kumba-common/src/libs/apollo';
import { createVet } from '../graphql-custom/mutations';
import { push } from 'connected-react-router';
import { LOGIN, PROFILE } from '../constants/routes';
import { genericSagaHandler } from './helpers';
import { indentify } from 'kumba-common/src/libs/analytics';
import { getUrlParam } from 'kumba-utils/src/html';

function* handleError(e) {
  yield put({
    type: APP_FAILURE,
    errorMessage: getText(`common.errors.${e.code}`),
    error: e,
  });
}

function* authSignupHandler(action) {
  const { password, email, kumbaRole } = action;
  try {
    const newUser = yield signup({
      password,
      kumbaRole,
      username: email,
    });
    const userId = newUser.userSub;
    // Sign in for all session tokens to make API calls
    yield signIn({ email, password });
    yield mutate({
      mutation: createVet,
      variables: {
        email,
        vetId: userId,
      },
    });

    yield put({ type: AUTH_LOGIN_SUCCESS });
    indentify({ email, userId });
    yield put({ password, email, userId, type: AUTH_SET });
  } catch (e) {
    yield handleError(e);
  }
}

function* authInitHandler() {
  try {
    let { userId, email } = yield getUserDetails();
    const actualUserId = userId;
    if (userId) {
      yield put({ type: AUTH_LOGIN_SUCCESS });
      const isAdminResponse = yield isAdmin();
      const actingUserId = getUrlParam('actingUserId');
      const isActingUser = isAdminResponse && actingUserId;
      if (isActingUser) {
        userId = actingUserId;
      }
      !isActingUser && indentify({ email, userId });
      yield put({
        userId,
        actualUserId,
        isAdmin: isAdminResponse,
        type: AUTH_SET,
      });
    } else {
      yield put(push(`/${LOGIN}`));
    }
  } catch (e) {
    console.error('Problem in auth init', e);
  }
}
function* authLogoutHandler() {
  try {
    yield signOut();
  } catch (e) {
    console.error('Problem in auth logout', e);
  }
}

function* authLoginHandler(action) {
  const { email, password } = action;
  yield put({ type: APP_SHOW_SPINNER });
  try {
    const { userId } = yield signIn({ email, password });
    indentify({ email, userId });
    yield put({ password, email, userId, type: AUTH_SET });
    yield put({ type: AUTH_LOGIN_SUCCESS });
  } catch (e) {
    yield handleError(e);
  } finally {
    yield put({ type: APP_HIDE_SPINNER });
  }
}

function* authForgotPasswordHandler(action) {
  const { email, code, new_password } = action;

  if (code && new_password) {
    // Collect confirmation code and new password
    const data = forgotPasswordSubmit({ email, code, new_password });

    yield put({ type: AUTH_INIT });

    yield put({
      type: APP_SHOW_MESSAGE,
      message: getText('forgotPassword.changeSuccess'),
    });
    yield put(push(`/${PROFILE}`));
  } else {
    const data = yield forgotPassword(email);
    yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS });
  }
}

function* authSaga() {
  yield takeEvery(AUTH_SIGN_UP, genericSagaHandler(authSignupHandler));
  yield takeEvery(AUTH_INIT, genericSagaHandler(authInitHandler));
  yield takeEvery(AUTH_LOGOUT, genericSagaHandler(authLogoutHandler));
  yield takeEvery(AUTH_LOGIN, genericSagaHandler(authLoginHandler));
  yield takeEvery(
    AUTH_FORGOT_PASSWORD,
    genericSagaHandler(authForgotPasswordHandler)
  );
}

export default authSaga;
