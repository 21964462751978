import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { useQuery } from 'react-apollo-hooks';

import PageFilter from 'kumba-components/src/components/PageFilter';
import Text, { TextTypes } from 'kumba-components/src/components/Text';
import Spinner from 'kumba-components/src/components/Spinner';
import ActionMenu from 'kumba-components/src/components/ActionMenu';
import { petsFilterInputDefinition } from './input-definitions';
import { showMessage } from '../../actions/app-actions';
import { getPetsMedicalConsultations } from '../../graphql-custom/queries';
import gql from 'graphql-tag';
import { getText } from 'kumba-common/src/libs/i18n';
import MedicalConsultationsStyledComponent from './styled-component';
import initialState from '../../reducers/initial-state';
import { IMedicalConsultationPet, ConsultationPageModalIds } from './helpers';
import { getActingUserUrl } from 'kumba-common/src/services/url';
import UpsertConsultation from './upsert-consultation';
import PetTimeline from 'kumba-common/src/components/pet-timeline';
import { ButtonAdd } from 'kumba-components/src/components/Button';

interface MedicalConsultationsProps {
  showMessageTrigger: any;
  isAdmin: boolean;
}

function formatPetRowData({
  pets,
  setCurrModalId,
  setCurrPetId,
}: {
  pets: IMedicalConsultationPet[];
  setCurrModalId: (modalId: ConsultationPageModalIds) => void;
  setCurrPetId: (petId: string) => void;
}) {
  return pets.map(pet => ({
    id: pet.id,
    content: [
      { value: pet.name },
      { value: `${pet.petOwner.user.firstName} ${pet.petOwner.user.lastName}` },
      {
        value: (
          <ActionMenu
            id={pet.id}
            tooltip={''}
            menuItems={[
              {
                label: getText('medicalConsultations.actingUser'),
                onClick: () =>
                  window.open(
                    getActingUserUrl({
                      userId: pet.petOwner.user.userId,
                      preventEncoding: true,
                    }),
                    '_blank'
                  ),
              },
              {
                label: getText('medicalConsultations.addMedicalConsultation'),
                onClick: () => {
                  setCurrPetId(pet.id);
                  setCurrModalId(ConsultationPageModalIds.UPSERT_CONSULTATION);
                },
              },
            ]}
          />
        ),
      },
    ],
  }));
}

function MedicalConsultations({
  showMessageTrigger,
  isAdmin,
}: MedicalConsultationsProps) {
  const [filterValues, setFilterValues] = React.useState({
    petName: undefined,
    ownerFirstName: undefined,
    ownerLastName: undefined,
  });
  const [currModalId, setCurrModalId] = React.useState();
  const [currConsulation, setCurrConsulation] = React.useState();
  const [currPetId, setCurrPetId] = React.useState('');

  const {
    data,
    error,
    loading,
    refetch: refetchMedicalConsultation,
  } = useQuery(gql(getPetsMedicalConsultations), {
    variables: filterValues,
    fetchPolicy: 'no-cache',
  });
  React.useEffect(() => {
    refetchMedicalConsultation().catch(e => console.error(e));
  }, [filterValues]);

  if (loading) {
    return <Spinner />;
  } else if (error) {
    console.error(error);
    return <div>Something went wrong...</div>;
  } else {
    return (
      <MedicalConsultationsStyledComponent>
        <PageFilter
          inputDefinitions={petsFilterInputDefinition}
          header={getText('medicalConsultation.filterHeader')}
          onSubmit={values => setFilterValues(values)}
        />
        {!!data.pets.length &&
          data.pets.map(currPet => (
            <div key={currPet.id}>
              <Text textType={TextTypes.H4}>{currPet.name}</Text>
              <ButtonAdd
                onClick={() => {
                  setCurrPetId(currPet.id);
                  setCurrModalId(ConsultationPageModalIds.UPSERT_CONSULTATION);
                }}
                text={getText('medicalConsultations.addMedicalConsultation')}
              />
              <PetTimeline
                petId={currPet.id}
                isAdmin={isAdmin}
                isVet={true}
                onEditConsultationClicked={({
                  consultationId,
                  summary,
                  documentGroupUrl,
                  consultationDate,
                }) => {
                  setCurrPetId(currPet.id);
                  setCurrConsulation({
                    consultationId,
                    summary,
                    documentGroupUrl,
                    consultationDate,
                  });
                  setCurrModalId(ConsultationPageModalIds.UPSERT_CONSULTATION);
                }}
              />
              <hr />
            </div>
          ))}
        {/* {!!data.pets.length && (
          <Table
            columns={[
              { title: getText('petName') },
              { title: getText('owner') },
              { title: '' },
            ]}
            rows={formatPetRowData({
              pets: data.pets,
              setCurrModalId,
              setCurrPetId,
            })}
          />
        )} */}
        {currModalId === ConsultationPageModalIds.UPSERT_CONSULTATION && (
          <UpsertConsultation
            onClose={({ isPostUpdate }) => {
              setCurrModalId(null);
              isPostUpdate && setTimeout(() => location.reload(), 500);
            }}
            petId={currPetId}
            currConsulation={currConsulation}
          />
        )}
      </MedicalConsultationsStyledComponent>
    );
  }
}

const mapStateToProps = (state = initialState) => {
  return {
    isAdmin: state.auth.isAdmin,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { showMessageTrigger: showMessage }
  )
)(MedicalConsultations);
