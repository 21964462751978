import * as React from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo-hooks';

import initialState from '../../reducers/initial-state';
import PageFilter from 'kumba-components/src/components/PageFilter';
import Scorecard from 'kumba-components/src/components/Scorecard';
import Spinner from 'kumba-components/src/components/Spinner';
import Table from 'kumba-components/src/components/Table';
import gql from 'graphql-tag';
import DashboardStyledComponent from './styled-component';
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  updateFilterWithInitialValues,
  prepareActivityTableRows,
  formatActivityData,
  getTotalPayment,
} from './helpers';
import { addUnitsToDate } from 'kumba-utils/src/date';
import TimeUnits from 'kumba-utils/src/enums/time-units';
import { getVetActivity } from '../../graphql-custom/queries';
import { getText } from 'kumba-common/src/libs/i18n';
import { IVetActivityData } from './interfaces';
import { addCurrencyToPrice } from 'kumba-common/src/services/localization';

function Activity({ vetId }) {
  const [filterValues, setFilterValues] = React.useState({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });
  const {
    data: vetActivityData,
    error: vetActivityError,
    loading: vetActivityLoading,
    refetch: vetActivityRefetch,
  } = useQuery(gql(getVetActivity), {
    variables: {
      ...filterValues,
      vetId,
      endDate: addUnitsToDate({
        unit: TimeUnits.DAYS,
        amount: 1,
        date: filterValues.endDate,
        format: 'YYYY-MM-DD',
      }),
    },
  });

  React.useEffect(() => {
    vetActivityRefetch().catch(e => console.error(e));
  }, [filterValues, vetId]);

  if (vetActivityLoading) {
    return <Spinner />;
  } else if (vetActivityError) {
    console.error(`Error in getting dashboard data`, vetActivityError);
    return <div>Something went wrong...</div>;
  } else {
    const numAppointments = (vetActivityData as IVetActivityData).appointments
      .length;
    const vetActivityFormatted = formatActivityData({ vetActivityData });
    const totalPay = getTotalPayment({ vetActivityFormatted, numAppointments });
    return (
      <DashboardStyledComponent>
        <PageFilter
          inputDefinitions={updateFilterWithInitialValues(filterValues)}
          header={getText('activity.title')}
          onSubmit={values => setFilterValues(values)}
        />
        <div className="scorecard-row">
          <Scorecard
            title={getText('activity.numVisits')}
            value={numAppointments}
          />
          <Scorecard
            title={getText('activity.payment')}
            value={addCurrencyToPrice({ price: totalPay })}
          />
        </div>
        <Table
          tableHeader={getText('activity.breakdown')}
          rows={prepareActivityTableRows({
            numAppointments,
            vetActivityFormatted,
          })}
          columns={[
            { title: getText('name') },
            { title: getText('quantity') },
            { title: getText('price') },
            { title: getText('activity.vetMargin') },
          ]}
        />
      </DashboardStyledComponent>
    );
  }
}

const mapStateToProps = (state = initialState) => ({
  vetId: state.auth.userId,
});
export default connect(mapStateToProps)(Activity);
