import * as React from 'react';
import { connect } from 'react-redux';

import { compose, graphql } from 'react-apollo';

import SlotsStyledComponent from './styled-component';
import Spinner from 'kumba-components/src/components/Spinner';

import gql from 'graphql-tag';
import initialState from '../../reducers/initial-state';

import { SLOT_CREATE } from '../../constants/routes';
import { Link } from 'react-router-dom';
import Confirm from 'kumba-components/src/components/Confirm';
import TabSet from 'kumba-components/src/components/TabSet';
import { slotDelete, slotDuplicate } from '../../actions/slot-actions';
import { getText } from 'kumba-common/src/libs/i18n';
import { getVetTimeSlots } from '../../graphql-custom/queries';

import { ButtonAdd } from 'kumba-components/src/components/Button/ButtonAdd';
import TimeSlotList from './time-slot-list';
import Text, { TextTypes } from 'kumba-components/src/components/Text';

interface SlotsProps {
  slots: { loading: boolean; vet: any; refetch: any; error: string };
  vetId: string;
  slotDeleteTrigger: any;
  slotDuplicateTrigger: any;
  history: any;
  isAdmin: boolean;
}

function onSlotDelete({ slotId, setIsDeleteConfirmShown, setCurrentSlotId }) {
  setIsDeleteConfirmShown(true);
  setCurrentSlotId(slotId);
}

function onSlotDuplicate({
  slotId,
  setIsSpinnerShown,
  vetId,
  slotDuplicateTrigger,
}) {
  setIsSpinnerShown(true);
  slotDuplicateTrigger({ slotId, vetId });
}

function SlotsComponent({
  isAdmin,
  slots,
  slotDuplicateTrigger,
  vetId,
  slotDeleteTrigger,
  history,
}: SlotsProps) {
  const [isDeleteConfirmShown, setIsDeleteConfirmShown] = React.useState(false);
  const [currentSlotId, setCurrentSlotId] = React.useState('');
  const [isSpinnerShown, setIsSpinnerShown] = React.useState(false);

  if (slots.loading || isSpinnerShown || !slots.vet) {
    return <Spinner />;
  } else if (slots.error) {
    console.error('Error fetching vet timeslot', slots.error);
    return <div>Something went wrong...</div>;
  } else {
    return (
      <SlotsStyledComponent>
        <div className="slots-header">
          <Text textType={TextTypes.H2}>{getText('slots.title')}</Text>
          {!!isAdmin && (
            <div className="add-slot">
              <Link to={`/${SLOT_CREATE}`}>
                <ButtonAdd
                  text={getText('slots.create')}
                  automationId="slotCreate"
                />
              </Link>
            </div>
          )}
        </div>

        <TabSet
          tabs={[
            {
              label: getText('slots.futureSlots'),
              content: (
                <TimeSlotList
                  history={history}
                  isPastSlots={false}
                  slots={slots.vet.timeSlots}
                  onSlotDelete={slotId =>
                    onSlotDelete({
                      slotId,
                      setIsDeleteConfirmShown,
                      setCurrentSlotId,
                    })
                  }
                  onSlotDuplicate={slotId =>
                    onSlotDuplicate({
                      slotId,
                      setIsSpinnerShown,
                      vetId,
                      slotDuplicateTrigger,
                    })
                  }
                />
              ),
            },
            {
              label: getText('slots.pastSlots'),
              content: (
                <TimeSlotList
                  history={history}
                  isPastSlots={true}
                  slots={slots.vet.timeSlots}
                  onSlotDelete={slotId =>
                    onSlotDelete({
                      slotId,
                      setIsDeleteConfirmShown,
                      setCurrentSlotId,
                    })
                  }
                  onSlotDuplicate={slotId =>
                    onSlotDuplicate({
                      slotId,
                      setIsSpinnerShown,
                      vetId,
                      slotDuplicateTrigger,
                    })
                  }
                />
              ),
            },
          ]}
        />

        <Confirm
          onClose={() => setIsDeleteConfirmShown(false)}
          onConfirm={() => {
            setIsDeleteConfirmShown(false);

            slotDeleteTrigger(currentSlotId);
            setTimeout(() => {
              slots.refetch();
            }, 2000);
          }}
          title={getText('slots.delete')}
          content={getText('slots.delete.message')}
          isOpen={isDeleteConfirmShown}
        />
      </SlotsStyledComponent>
    );
  }
}

const mapStateToProps = (state = initialState) => {
  return {
    vetId: state.auth.userId,
    isAdmin: state.auth.isAdmin,
  };
};

export default compose(
  graphql(gql(getVetTimeSlots), {
    name: 'slots',
    options: (props: any) => ({
      fetchPolicy: 'no-cache',
      variables: { vetId: props.id },
    }),
  }),
  connect(
    mapStateToProps,
    { slotDeleteTrigger: slotDelete, slotDuplicateTrigger: slotDuplicate }
  )
)(SlotsComponent);
