import * as React from 'react';
import styled from 'styled-components';
import RedoIcon from '@material-ui/icons/Redo';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { RoutePet } from './interfaces';
import {
  List,
  ListItem,
  Avatar,
  Typography,
  withTheme,
} from '@material-ui/core';
import Button from 'kumba-components/src/components/Button';
import ButtonCall from 'kumba-components/src/components/Button/ButtonCall';
import PetTypes from 'kumba-common/src/enums/pet-types';
// @ts-ignore
import anonymousDog from 'kumba-common/src/images/anonymous-dog.png';
// @ts-ignore
import anonymousCat from 'kumba-common/src/images/anonymous-cat.png';
import { getIntervalFromDate } from 'kumba-utils/src/date';
import ButtonNavigate from 'kumba-components/src/components/Button/ButtonNavigate';

const RouteInfoWindowStyledComponent = styled.div`
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
      padding-bottom: 0;
    }
  }
  li {
    padding-left: 0;
    padding-right: 0;
    &.visited {
      text-decoration: line-through;
    }
  }
  .new-icon svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface RouteInfoWindowProps {
  pets: RoutePet[];
  slotId: string;
  onPetClicked: ({ slotId, appointmentId }) => void;
  petOwnerName: string;
  petOwnerPhone: string;
  address: string;
  lat: number;
  lng: number;
  arePetsToVisit: boolean;
  theme: any;
  onNavigationButtonClick: () => void;
}

function getPetPicture({ profilePictureUrl, petType }) {
  if (profilePictureUrl) {
    return profilePictureUrl;
  } else {
    return petType === PetTypes.DOG ? anonymousDog : anonymousCat;
  }
}

function RouteInfoWindow({
  pets,
  slotId,
  onPetClicked,
  petOwnerName,
  petOwnerPhone,
  address,
  arePetsToVisit,
  lat,
  lng,
  theme,
  onNavigationButtonClick,
}: RouteInfoWindowProps) {
  return (
    <RouteInfoWindowStyledComponent className="route-info-window" theme={theme}>
      <div className="top-row">
        <div>
          <ButtonCall phoneNumber={petOwnerPhone} />
          <ButtonNavigate
            addressLat={lat}
            addressLng={lng}
            onClick={onNavigationButtonClick}
          />
        </div>

        <Typography variant="subtitle1">{petOwnerName}</Typography>
        {!arePetsToVisit && <CheckBoxIcon />}
      </div>
      <Typography variant="subtitle2">{address}</Typography>
      <hr />
      <List dense={true}>
        {pets.map(pet => {
          return (
            <ListItem
              key={pet.id}
              dense={true}
              className={pet.isVisited ? 'visited' : ''}
            >
              <Avatar
                src={getPetPicture({
                  profilePictureUrl: pet.profilePictureUrl,
                  petType: pet.petType,
                })}
              />
              &nbsp;
              {!!pet.isfirstTimeVisit && (
                <span className="new-icon">
                  <FiberNewIcon />
                </span>
              )}
              &nbsp;
              {`${pet.name}${
                pet.startDate
                  ? `${
                      getIntervalFromDate({ stringDate: pet.startDate }).split(
                        ','
                      )[1]
                    }`
                  : ''
              }`}
              <Button
                variant="text"
                onClick={() =>
                  onPetClicked({ slotId, appointmentId: pet.appointmentId })
                }
              >
                <RedoIcon />
              </Button>
            </ListItem>
          );
        })}
      </List>
    </RouteInfoWindowStyledComponent>
  );
}

export default withTheme(RouteInfoWindow);
