import styled from 'styled-components';

const routeStyledComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;
  .directions {
    .kumba-google-map {
      height: calc(100vh - 180px);
    }
    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .route-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      padding: 0;
    }
  }
`;

export default routeStyledComponent;
