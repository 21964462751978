import * as React from 'react';

// @ts-ignore
import SadDog from 'kumba-common/src/images/sad-dog.jpg';
import { getText } from 'kumba-common/src/libs/i18n';

const NotFound = () => (
  <div
    style={{
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr 1fr',
    }}
  >
    <h1>{getText('notFount.title')}</h1>
    <img style={{ height: '50vh' }} src={SadDog} alt="Sad Dog" />
  </div>
);

export default NotFound;
