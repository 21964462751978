import {
  InputDefinition,
  InputTypes,
} from 'kumba-components/src/components/Form';
import { getText } from 'kumba-common/src/libs/i18n';

const profileInputDefinitions: InputDefinition[] = [
  {
    inputType: InputTypes.Text,
    label: getText('profile.form.firstName'),
    name: 'firstName',
    isRequired: true,
  },
  {
    inputType: InputTypes.Text,
    label: getText('profile.form.lastName'),
    name: 'lastName',
    isRequired: true,
  },
  {
    inputType: InputTypes.Text,
    label: getText('profile.form.license'),
    name: 'license',
    isRequired: true,
  },
  {
    inputType: InputTypes.Address,
    label: getText('common.address'),
    name: 'address',
    isRequired: false,
  },
  {
    inputType: InputTypes.Phone,
    label: getText('common.phone'),
    name: 'phone',
    isRequired: true,
  },
  {
    inputType: InputTypes.Select,
    label: getText('profile.form.tShirtSize'),
    name: 'tShirtSize',
    helperText: getText('profile.form.tShirtSizeHelper'),
    isRequired: false,
    options: [
      { label: 'XS', value: 'XS' },
      { label: 'S', value: 'S' },
      { label: 'M', value: 'M' },
      { label: 'L', value: 'L' },
      { label: 'XL', value: 'XL' },
    ],
  },
  {
    inputType: InputTypes.File,
    label: getText('profile.form.profilePicture'),
    name: 'profilePictureUrl',
    isRequired: false,
  },
];

export default profileInputDefinitions;
