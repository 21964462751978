import * as React from 'react';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { getText } from 'kumba-common/src/libs/i18n';
import { SLOT_EDIT, ROUTE } from '../../constants/routes';
import { Link } from 'react-router-dom';
import ButtonEdit from 'kumba-components/src/components/Button/ButtonEdit';
import ButtonDelete from 'kumba-components/src/components/Button/ButtonDelete';
import ButtonDuplicate from 'kumba-components/src/components/Button/ButtonDuplicate';
import { ButtonRoute } from 'kumba-components/src/components/Button/ButtonRoute';
interface SlotButtonSetProps {
  slot: any;
  onSlotDelete: any;
  onSlotDuplicate: any;
  history: any;
  isEditable: boolean;
}

function SlotButtonSet({
  slot,
  onSlotDelete,
  isEditable,
  onSlotDuplicate,
  history,
}: SlotButtonSetProps) {
  const hasAppointmentsScheduled =
    slot.appointments && !!slot.appointments.length;
  return (
    <ListItem className="button-group">
      <div>
        {hasAppointmentsScheduled && (
          <Link to={`${ROUTE.replace(':slotId', slot.id)}`}>
            <ButtonRoute text={getText(`slots.routeDetails`)} />
          </Link>
        )}
        <ButtonDuplicate
          onClick={() => onSlotDuplicate(slot.id)}
          tooltip={getText(`slots.duplicate`)}
        />

        {!!isEditable && (
          <ButtonEdit
            onClick={() =>
              history.push(`/${SLOT_EDIT.replace(':slotId', slot.id)}`)
            }
            tooltip={getText(`slots.edit`)}
          />
        )}
      </div>

      {!!isEditable && (
        <ButtonDelete
          isDisabled={hasAppointmentsScheduled}
          onClick={() => !hasAppointmentsScheduled && onSlotDelete(slot.id)}
          tooltip={
            hasAppointmentsScheduled
              ? getText('slots.deleteDisabled')
              : getText(`slots.delete`)
          }
        />
      )}
    </ListItem>
  );
}

export default SlotButtonSet;
