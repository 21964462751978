import {
  APP_FAILURE,
  APP_SHOW_SPINNER,
  APP_HIDE_SPINNER,
} from './../constants/action-types';
import { put } from 'redux-saga/effects';
import { getText } from 'kumba-common/src/libs/i18n';
import { sanitizeActionData } from 'kumba-utils/src/security';
import { isProd } from 'kumba-common/src/libs/env';

export function genericSagaHandler(cb) {
  return function*(action) {
    try {
      isProd() &&
        console.log(
          `Starting saga for action ${action.type} with the following data`,
          sanitizeActionData(action)
        );
      yield put({ type: APP_SHOW_SPINNER });
      yield cb(action);
    } catch (e) {
      yield handleError(e);
    } finally {
      yield put({ type: APP_HIDE_SPINNER });
    }
  };
}

export function* handleError(e) {
  console.error(e);
  yield put({
    type: APP_FAILURE,
    errorMessage: getText(`common.errors.${e.code}`),
    error: e,
  });
}
