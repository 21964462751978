import * as React from 'react';
import { connect } from 'react-redux';

import { compose, graphql } from 'react-apollo';

import RouteStyledComponent from './styled-component';
import Spinner from 'kumba-components/src/components/Spinner';
import gql from 'graphql-tag';
import MapIcon from '@material-ui/icons/Map';
import TableChartIcon from '@material-ui/icons/TableChart';

import { formatRouteData } from './helpers';
import { getText } from 'kumba-common/src/libs/i18n';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonTypes } from 'kumba-components/src/components/Button';
import { routeStart, routeEnd } from '../../actions/route-actions';
import { getRoute } from '../../graphql-custom/queries';
import RouteDirections from './route-directions';
import { APPOINTMENT } from '../../constants/routes';
import Tooltip from 'kumba-components/src/components/Tooltip';
import TabSet from 'kumba-components/src/components/TabSet';
import FormModal from 'kumba-components/src/components/FormModal';
import { InputTypes } from 'kumba-components/src/components/Form/helpers';
import { slotUpdateVetComment } from '../../actions/slot-actions';
import RouteAccordionView from './route-accordion-view';
import initialState from 'kumba-pets/src/reducers/initial-state';
import Confirm from 'kumba-components/src/components/Confirm';
import Text from 'kumba-components/src/components/Text';
import { ITimelineEvent } from 'kumba-components/src/components/Timeline/helpers';

interface RouteProps {
  route: { loading: boolean; error: string; timeSlot: any };
  history;
  routeStart: any;
  routeEnd: any;
  slotUpdateVetComment: any;
  isAdmin: boolean;
}

interface RouteState {
  isVetCommentModalShown: boolean;
  isStartRouteConfirmShown: boolean;
}

class RouteComponent extends React.Component<RouteProps, RouteState> {
  state = {
    isVetCommentModalShown: false,
    isStartRouteConfirmShown: false,
  };

  componentDidUpdate = () => {
    if (!this.props.route.loading) {
      const route = formatRouteData({
        route: this.props.route,
      });

      const hasUnfinishedAppointments = !!route.appointments.filter(
        a => !a.actualEndDate
      ).length;

      if (
        route.actualStartDate &&
        !route.actualEndDate &&
        !this.state.isVetCommentModalShown &&
        !hasUnfinishedAppointments
      ) {
        this.setState({
          isVetCommentModalShown: true,
        });
      }
    }
  };

  handleVetCommentModalClose = slotId => {
    this.setState(
      {
        isVetCommentModalShown: false,
      },
      () => {
        this.props.routeEnd({ slotId });
      }
    );
  };

  handleNavigationButtonClicked = ({ route }) => {
    if (!route.actualStartDate) {
      this.setState({ isStartRouteConfirmShown: true });
    }
  };

  handleVetCommentSubmit = ({ vetComment, slotId }) => {
    this.handleVetCommentModalClose(slotId);
    this.props.slotUpdateVetComment({ slotId, vetComment });
  };

  handlePetClicked = ({ appointmentId, slotId }) => {
    const newUrl = APPOINTMENT.replace(':slotId', slotId).replace(
      ':appointmentId',
      appointmentId
    );
    this.props.history.push(`/${newUrl}`);
  };

  onEditAppointment = (timelineEvent: ITimelineEvent) => {
    const route = formatRouteData({
      route: this.props.route,
    });
    const newUrl = APPOINTMENT.replace(':slotId', route.id).replace(
      ':appointmentId',
      timelineEvent.id
    );
    this.props.history.push(`/${newUrl}`);
  };

  render() {
    if (this.props.route.loading) {
      return <Spinner />;
    } else if (this.props.route.error) {
      console.error(this.props.route.error);
      return <div>Something went wrong...</div>;
    } else {
      const route = formatRouteData({
        route: this.props.route,
      });

      return (
        <RouteStyledComponent>
          <div className="route-top-row">
            <Text>
              {route.name} -{' '}
              {getText(
                'route.numPets',
                { numPets: route.appointments.length },
                route.appointments.length === 1
              )}{' '}
              ({route.vetName})
            </Text>

            <Typography variant="h5" />
            {!route.actualStartDate && (
              <Button
                isDisabled={route.isOpenForScheduling && !this.props.isAdmin}
                variant="contained"
                buttonType={ButtonTypes.Primary}
                onClick={() => this.props.routeStart({ slotId: route.id })}
              >
                {getText('route.start')}
              </Button>
            )}
          </div>
          {!!route.actualStartDate && (
            <Text>
              {getText('route.startTime', { startTime: route.actualStartDate })}
            </Text>
          )}
          {!!route.actualEndDate && (
            <Text>
              {getText('route.endTime', { endTime: route.actualEndDate })}
            </Text>
          )}
          <TabSet
            initialTabIndex={
              route.actualStartDate && !route.actualEndDate ? 0 : 1
            }
            tabs={[
              {
                label: <MapIcon />,
                content: (
                  <RouteDirections
                    onPetClicked={this.handlePetClicked}
                    route={route}
                    onNavigationButtonClick={() =>
                      this.handleNavigationButtonClicked({ route })
                    }
                  />
                ),
              },
              {
                label: <TableChartIcon />,
                content: (
                  <RouteAccordionView
                    appointments={this.props.route.timeSlot.appointments}
                    onPetClicked={this.handlePetClicked}
                    slotId={this.props.route.timeSlot.id}
                    isAdmin={this.props.isAdmin}
                    onEditAppointment={this.onEditAppointment}
                  />
                ),
              },
            ]}
          />

          <Confirm
            onClose={() => this.setState({ isStartRouteConfirmShown: false })}
            onConfirm={() => this.props.routeStart({ slotId: route.id })}
            title={''}
            content={getText('route.startRouteConfirm')}
            isOpen={this.state.isStartRouteConfirmShown}
          />

          <FormModal
            isOpen={this.state.isVetCommentModalShown}
            formTitle={getText('route.vetComment')}
            inputDefinitions={[
              {
                inputType: InputTypes.TextArea,
                name: 'vetComment',
                isRequired: false,
                label: getText('route.vetComment'),
                helperText: getText('route.vetCommentHelper'),
              },
            ]}
            onClose={() => this.handleVetCommentModalClose(route.id)}
            onSubmit={({ vetComment }) =>
              this.handleVetCommentSubmit({ vetComment, slotId: route.id })
            }
            submitLabel={getText('common.buttons.save')}
            cancelLabel={getText('common.buttons.cancel')}
          />
        </RouteStyledComponent>
      );
    }
  }
}
const mapStateToProps = (state = initialState) => ({
  isAdmin: state.auth.isAdmin,
});

export default compose(
  graphql(gql(getRoute), {
    name: 'route',
    options: (props: any) => ({
      variables: { routeId: props.match.params.slotId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(
    mapStateToProps,
    { routeStart, routeEnd, slotUpdateVetComment }
  )
)(RouteComponent);
