/* eslint-disable react/display-name */
import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Spinner from 'kumba-components/src/components/Spinner';

export default ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (cProps.isLoggedIn === false) {
        return (
          <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />
        );
      } else if (cProps.isLoggedIn === true && cProps.id) {
        return <C {...props} {...cProps} />;
      } else {
        return <Spinner />;
      }
    }}
  />
);
