import * as React from 'react';
import { connect } from 'react-redux';

import Table from 'kumba-components/src/components/Table';
import { getText } from 'kumba-common/src/libs/i18n';
import initialState from '../../../reducers/initial-state';
import {
  InventoryItem,
  prepareInventoryRows,
  updateInventoryQuantity,
} from '../helpers';
import { showMessage } from '../../../actions/app-actions';

interface InventoryTableProps {
  inventoryData: InventoryItem[];
  isAdmin: boolean;
  onQuantityUpdated: () => void;
  showMessageTrigger: any;
}

function InventoryTable({
  inventoryData,
  isAdmin,
  showMessageTrigger,
  onQuantityUpdated,
}: InventoryTableProps) {
  const [currEditedProductId, setCurrEditedProductId] = React.useState('');
  const [currQuantity, setCurrQuantity] = React.useState(0);
  return (
    <Table
      columns={[
        { title: getText('inventory.product') },
        { title: getText('inventory.remainingQuantity') },
      ]}
      rows={prepareInventoryRows({
        isAdmin,
        inventoryData,
        currEditedProductId,
        currRemainingQuantity: currQuantity,
        onEditQuantityChanged: currQuantity => setCurrQuantity(currQuantity),
        onEditRowClicked: ({ inventoryProductId, currQuantity }) => {
          setCurrEditedProductId(inventoryProductId);
          setCurrQuantity(currQuantity);
        },
        onUpdateQuantity: async () => {
          await updateInventoryQuantity({ currEditedProductId, currQuantity });

          setCurrEditedProductId('');
          setCurrQuantity(0);
          showMessageTrigger({
            message: getText('inventory.quantitySuccess'),
          });
          onQuantityUpdated();
        },
      })}
    />
  );
}
const mapStateToProps = (state = initialState) => {
  return {
    isAdmin: state.auth.isAdmin,
  };
};
export default connect(
  mapStateToProps,
  { showMessageTrigger: showMessage }
)(InventoryTable);
