import * as React from 'react';
import { connect } from 'react-redux';

import { compose, graphql } from 'react-apollo';

import AppointmentStyledComponent from './styled-component';
import Spinner from 'kumba-components/src/components/Spinner';

import gql from 'graphql-tag';
import {
  appointmentStart,
  appointmentEdit,
  appointmentEnd,
} from '../../actions/appointment-actions';
import { getAppointment, getAllProducts } from '../../graphql-custom/queries';
import {
  Appointment,
  Product,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import CheckboxGroup from 'kumba-components/src/components/CheckboxGroup';
import {
  formatProductsInSubscription,
  getCurrentVisitPetProduct,
  checkNonExpiredSubscription,
} from './helpers';
import { get } from 'lodash';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getText, getTextDirection } from 'kumba-common/src/libs/i18n';
import Button, { ButtonTypes } from 'kumba-components/src/components/Button';
import { formatStringDate } from 'kumba-utils/src/date';
import Input from 'kumba-components/src/components/Input';
import { InputTypes } from 'kumba-components/src/components/Form';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../constants/routes';
import TextDirections from 'kumba-utils/src/enums/text-directions';
import KumbaApps from 'kumba-common/src/enums/kumba-apps';
import AlertBox, {
  AlertBoxVariants,
} from 'kumba-components/src/components/AlertBox';
import RabiesFields from './rabies-fields';
import AppointmentPetAccordion from 'kumba-common/src/components/appointment-pet-accordion';
import NoShow from './no-show';
import initialState from '../../reducers/initial-state';
import ProductPicker from './product-picker';
import AppointmentProduct from 'kumba-common/src/interfaces/appointment-product';
import PetTypes from 'kumba-common/src/enums/pet-types';
import Text from 'kumba-components/src/components/Text';

interface AppointmentProps {
  match: any;
  appointmentData: {
    loading: boolean;
    error: string;
    appointment: Appointment;
  };

  productsData: {
    loading: boolean;
    error: string;
    products: Product[];
  };

  appointmentStart: any;
  appointmentEdit: any;
  appointmentEnd: any;
  isAdmin: boolean;
}

interface AppointmentState {
  [x: string]: any;
  selectedProductsInSubscription: string[];
  selectedProducts: AppointmentProduct[];
  isRabiesSelectedFromProductPicker: boolean;
}

class AppointmentComponent extends React.Component<
  AppointmentProps,
  AppointmentState
> {
  constructor(props) {
    super(props);

    this.state = {
      selectedProductsInSubscription: [],
      selectedProducts: [],
      vetComment: '',
      adminComment: '',
      chipNumber: '',
      weight: null,
      isRabiesSelectedFromProductPicker: false,
    };
  }

  triggerAppointmentEdit = ({ appointment }) => {
    this.props.appointmentEdit({
      appointment,
      slotId: this.props.match.params.slotId,
      adminComment: this.state.adminComment,
    });
  };

  triggerAppointmentEnd = ({
    weight,
    appointment,
    visitPetProduct,
    isNoShow = false,
    isRabiesGiven = false,
  }) => {
    this.props.appointmentEnd({
      weight,
      appointment,
      visitPetProduct,
      isNoShow,
      slotId: this.props.match.params.slotId,
      petOwnerId: get(appointment, 'pet.petOwner.user.userId'),
      chipNumber: this.state.chipNumber || appointment.pet.chipNumber,
      selectedProductIdsInSubscription: this.state
        .selectedProductsInSubscription,
      selectedProducts: this.state.selectedProducts,
      vetComment: this.state.vetComment,
      idNumber:
        this.state.idNumber || get(appointment, 'pet.petOwner.user.idNumber'),
      isRabiesGiven,
    });
  };

  onCheckboxChangedCommon = ({ isChecked, selectedName, value }) => {
    let newSelectedCheckboxes;
    if (isChecked) {
      newSelectedCheckboxes = this.state[selectedName].concat([value]);
    } else {
      newSelectedCheckboxes = this.state[selectedName].filter(s => s !== value);
    }

    this.setState({
      [selectedName]: newSelectedCheckboxes,
    });
  };

  handleInputChange = (inputName: string, value) => {
    this.setState({
      [inputName]: value,
    });
  };

  showStartAppointmentButton(appointment) {
    return (
      !!appointment.timeSlot.actualStartDate && !appointment.actualStartDate
    );
  }

  shouldDisableEndAppointment = ({ isRabiesGiven, appointment }) => {
    const hasChipNumber =
      !!this.state.chipNumber || !!appointment.pet.chipNumber;
    const hasIdNumber =
      !!this.state.idNumber || !!appointment.pet.petOwner.user.idNumber;

    return isRabiesGiven && (!hasChipNumber || !hasIdNumber);
  };

  componentDidUpdate(prevProps) {
    if (
      !this.props ||
      !this.props.appointmentData ||
      !this.props.appointmentData.appointment
    )
      return;
    if (this.hasAppointmentPropChanged(prevProps.appointmentData.appointment)) {
      console.log('initializing appointment data');

      this.initAppointment(this.props.appointmentData.appointment);
    }
  }

  hasAppointmentPropChanged(prevAppointment) {
    if (!prevAppointment) return true;
    const currAppointment = this.props.appointmentData.appointment;
    return (
      !prevAppointment ||
      prevAppointment.vetComment !== currAppointment.vetComment ||
      prevAppointment.adminComment !== currAppointment.adminComment
    );
  }

  initAppointment(appointment) {
    this.setState({
      vetComment: appointment.vetComment || '',
      adminComment: appointment.adminComment || '',
    });
  }

  render() {
    if (this.props.appointmentData.loading || this.props.productsData.loading) {
      return <Spinner />;
    } else if (
      this.props.appointmentData.error ||
      this.props.productsData.error ||
      !this.props.appointmentData.appointment
    ) {
      console.error(
        `Something is wrong with appointment id ${this.props.match.params.appointmentId}`,
        this.props.appointmentData.error
      );
      return <div>Something went wrong...</div>;
    } else {
      const appointment: any = this.props.appointmentData.appointment;
      const petType = get(
        this,
        'props.appointmentData.appointment.pet.petType'
      );
      const formattedProductsInSubscription = formatProductsInSubscription({
        petType,
        appointment: this.props.appointmentData.appointment as any,
        selectedProductsInSubscription: this.state
          .selectedProductsInSubscription,
      });
      const isNonExpiredSubscription = checkNonExpiredSubscription({
        petServices: appointment.pet.petServices,
      });

      const isRabiesGiven =
        petType === PetTypes.DOG &&
        (!!formattedProductsInSubscription.find(
          v => v.label.toLowerCase() === 'rabies' && !!v.isChecked
        ) ||
          !!this.state.isRabiesSelectedFromProductPicker);

      const isEndAppointmentDisabled = this.shouldDisableEndAppointment({
        isRabiesGiven,
        appointment,
      });

      const weight =
        this.state.weight === null ? appointment.pet.weight : this.state.weight;
      const visitPetProduct = getCurrentVisitPetProduct(appointment);

      const canEdit = this.props.isAdmin;
      return (
        <AppointmentStyledComponent className="appointment">
          <Link
            to={`/${ROUTE.replace(':slotId', this.props.match.params.slotId)}`}
          >
            <Button variant={'text'}>
              {getTextDirection(KumbaApps.KUMBA_VETS) ===
              TextDirections.LEFT_TO_RIGHT ? (
                <ArrowBackIcon />
              ) : (
                <ArrowForwardIcon />
              )}
              &nbsp;
              {getText('appointment.backToRoute')}
            </Button>
          </Link>
          {!!appointment.actualStartDate && (
            <Text>
              {getText('appointment.startTime', {
                startTime: formatStringDate({
                  stringDate: appointment.actualStartDate,
                  format: 'HH:MM',
                }),
              })}
            </Text>
          )}
          {!!appointment.actualEndDate && (
            <Text>
              {getText('appointment.endTime', {
                endTime: formatStringDate({
                  stringDate: appointment.actualEndDate,
                  format: 'HH:MM',
                }),
              })}
            </Text>
          )}

          {this.showStartAppointmentButton(appointment) && (
            <NoShow
              onStartAppointment={() => {
                this.props.appointmentStart({
                  appointmentId: appointment.id,
                  slotId: this.props.match.params.slotId,
                });
              }}
              petOwnerPhone={get(appointment, 'pet.petOwner.user.phone')}
              petOwnerId={get(appointment, 'pet.petOwner.user.userId')}
              appointmentId={appointment.id}
              onNoShowTimerEnded={() => {
                if (!appointment.actualStartDate) {
                  this.triggerAppointmentEnd({
                    weight,
                    appointment,
                    visitPetProduct,
                    isNoShow: true,
                  });
                }
              }}
            />
          )}

          <AppointmentPetAccordion
            isOpen={!appointment.actualStartDate}
            appointment={appointment}
            canEdit={false} // not to show edit option here, loop navigation
            isVet={true}
          />
          <hr />

          {appointment.actualStartDate &&
            (!appointment.actualEndDate || canEdit) && (
              <React.Fragment>
                <fieldset
                  className="appointment-edit-disabable-elements"
                  disabled={!!appointment.actualEndDate}
                >
                  {!!formattedProductsInSubscription.length && (
                    <CheckboxGroup
                      groupLabel={getText('appointment.productsInSubscription')}
                      checkboxes={formattedProductsInSubscription}
                      onChange={({ isChecked, value }) =>
                        this.onCheckboxChangedCommon({
                          isChecked,
                          value,
                          selectedName: 'selectedProductsInSubscription',
                        })
                      }
                      helperText={getText('appointment.vaccinesHelper')}
                    />
                  )}
                  {!formattedProductsInSubscription.length && (
                    <AlertBox
                      variant={AlertBoxVariants.Info}
                      message={getText('appointment.noProductsInSubscription')}
                    />
                  )}
                  {!!isRabiesGiven && (
                    <AlertBox
                      variant={AlertBoxVariants.Info}
                      message={getText('appointment.rabiesFee')}
                    />
                  )}
                  <ProductPicker
                    products={this.props.productsData.products}
                    selectedProducts={this.state.selectedProducts}
                    isRabiesGiven={isRabiesGiven}
                    disabled={!!appointment.actualEndDate}
                    productIdsInSubscription={formattedProductsInSubscription.map(
                      v => v.productId
                    )}
                    petType={get(
                      this,
                      'props.appointmentData.appointment.pet.petType'
                    )}
                    onProductsChanged={newSelectedProducts =>
                      this.setState({ selectedProducts: newSelectedProducts })
                    }
                    onRabiesSelected={isRabiesQuantity =>
                      this.setState({
                        isRabiesSelectedFromProductPicker: isRabiesQuantity,
                      })
                    }
                    isNonExpiredSubscription={isNonExpiredSubscription}
                  />

                  {!!isRabiesGiven && (
                    <RabiesFields
                      idNumber={this.state.idNumber}
                      chipNumber={this.state.chipNumber}
                      appointment={appointment}
                      onInputChange={this.handleInputChange}
                    />
                  )}
                  <Input
                    name={'weight'}
                    value={weight}
                    label={getText('weight')}
                    minValue={1}
                    maxValue={150}
                    inputType={InputTypes.Number}
                    isDisabled={!!appointment.actualEndDate}
                    onChange={(e, val) =>
                      this.handleInputChange('weight', e.target.value)
                    }
                  />
                  <Input
                    name={'vetComment'}
                    value={this.state.vetComment}
                    label={getText('appointment.vetComment')}
                    inputType={InputTypes.TextArea}
                    isDisabled={!!appointment.actualEndDate}
                    onChange={(e, val) =>
                      this.handleInputChange('vetComment', e.target.value)
                    }
                  />
                </fieldset>

                <Input
                  name={'adminComment'}
                  value={this.state.adminComment}
                  label={getText('appointment.adminComment')}
                  inputType={InputTypes.TextArea}
                  onChange={(e, val) =>
                    this.handleInputChange('adminComment', e.target.value)
                  }
                />
                <Button
                  variant="contained"
                  buttonType={ButtonTypes.Primary}
                  isDisabled={isEndAppointmentDisabled}
                  onClick={() =>
                    !!appointment.actualEndDate && canEdit
                      ? this.triggerAppointmentEdit({
                          appointment,
                        })
                      : this.triggerAppointmentEnd({
                          appointment,
                          weight,
                          visitPetProduct,
                          isRabiesGiven,
                        })
                  }
                >
                  {!!appointment.actualEndDate && canEdit
                    ? getText('appointment.edit')
                    : getText('appointment.end')}{' '}
                  &nbsp;
                </Button>
              </React.Fragment>
            )}
        </AppointmentStyledComponent>
      );
    }
  }
}

function mapStateToProps(state = initialState) {
  return { isAdmin: state.auth.isAdmin };
}
export default compose(
  graphql(gql(getAppointment), {
    name: 'appointmentData',
    options: (props: any) => ({
      variables: { appointmentId: props.match.params.appointmentId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(gql(getAllProducts), {
    name: 'productsData',
  }),
  connect(
    mapStateToProps,
    { appointmentStart, appointmentEdit, appointmentEnd }
  )
)(AppointmentComponent);
