import { getNestedText } from 'kumba-common/src/libs/i18n';
import ProductTypes from 'kumba-common/src/enums/product-types.enum';
import { PET_VISIT_ORDER } from 'kumba-common/src/constants/pet-visit-order';
import { isProd } from 'kumba-common/src/libs/env';
import { addCurrencyToPrice } from 'kumba-common/src/services/localization';
import { prettyPrint } from 'kumba-utils/src/string';
import TimeZones from 'kumba-utils/src/enums/timezones';

import {
  PetType,
  Product,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import {
  isActivePetService,
  isNonExpiredPetService,
} from 'kumba-utils/src/screen-helpers/common/pet-services';

// Get pet products from active services that have remaining quantity
export function getRemainingProductsFromPetServices(
  petServices: { petProducts: any }[]
) {
  try {
    let petProducts: any = [];

    for (const currPetService of petServices) {
      if (currPetService.petProducts) {
        // && isActivePetService(currPetService)) { <-- Commented out to enable round close even when subscription date ended
        petProducts = petProducts.concat(
          currPetService.petProducts.filter(pp => pp.remainingQuantity > 0)
        );
      }
    }
    return petProducts;
  } catch (error) {
    console.warn(`Error in getRemainingProductsFromPetServices - `, error);
  }
}

export function checkNonExpiredSubscription({
  petServices,
}: {
  petServices: { service: { titleId: string } }[];
}) {
  // Not expired and not one time
  return !!petServices
    .filter(isNonExpiredPetService)
    .filter(currPetService =>
      currPetService.service.titleId.startsWith('YEARLY')
    ).length;
}

export function getCurrentVisitPetProduct(appointment) {
  const allPotentialVisits = getRemainingProductsFromPetServices(
    appointment.pet.petServices
  )
    .filter(pp => pp.serviceProduct.product.productType === ProductTypes.VISIT)
    .map(pp => {
      return {
        id: pp.id,
        remainingQuantity: pp.remainingQuantity,
        titleId: pp.serviceProduct.product.titleId,
        usageFrequencyDays: pp.serviceProduct.product.usageFrequencyDays,
      };
    });

  // Find lowest ranking visit with remaining quantity
  for (const currPetVisit of PET_VISIT_ORDER[appointment.pet.petType]) {
    const currVisit = allPotentialVisits.find(a => a.titleId === currPetVisit);
    if (currVisit && currVisit.remainingQuantity > 0) {
      return currVisit;
    }
  }

  // Wrapping in trycatch so monitoring will receive this error but won't crush the app
  try {
    throw new Error(
      `Could not find a visit with remaining quantity. Appointment details: ${prettyPrint(
        appointment
      )}`
    );
  } catch (error) {
    isProd() && console.log(error);
  }
}

export function getProductTitle({ titleId, remainingQuantity, price }) {
  const productTitle = getNestedText(`products.${titleId}`) || titleId;
  return remainingQuantity
    ? productTitle
    : `${productTitle} - ${addCurrencyToPrice({ price })}`;
}

export function formatProductsInSubscription({
  appointment,
  petType,
  selectedProductsInSubscription = [],
}: {
  appointment: { pet: { petServices: { petProducts: [] }[] } };
  petType: string;
  selectedProductsInSubscription: any[];
}) {
  const petProducts = getRemainingProductsFromPetServices(
    appointment.pet.petServices
  );
  return petProducts
    .filter(petProduct => {
      return (
        petProduct.serviceProduct.product.petType === petType &&
        petProduct.serviceProduct.product.productType !== ProductTypes.VISIT
      );
    })
    .map(petProduct => {
      const product = petProduct.serviceProduct.product;

      return {
        productId: product.id,
        label: getProductTitle({
          titleId: product.titleId,
          remainingQuantity: petProduct.remainingQuantity,
          price: product.price,
        }),
        value: petProduct.id,
        isChecked: selectedProductsInSubscription.includes(petProduct.id),
      };
    });
}

const VALID_PRODUCT_TYPES = [
  'MEDICATION',
  'MEDICAL_PRODUCT',
  'VACCINE',
  'MEDICAL_TREATMENT',
];

export function isRelevantByProductType({
  product,
  petType,
  currentTimezone,
  isRabiesGiven = false,
}: {
  product: Product;
  petType: PetType;
  currentTimezone: TimeZones;
  isRabiesGiven?: boolean;
}) {
  const validProductTypes = VALID_PRODUCT_TYPES.concat(
    isRabiesGiven ? [ProductTypes.FEE] : []
  );
  return (
    (!product.petType || product.petType === petType) &&
    validProductTypes.includes(product.productType) &&
    product.activityTimezones.includes(currentTimezone)
  );
}
