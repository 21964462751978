import * as React from 'react';

import { getNestedText, getText } from 'kumba-common/src/libs/i18n';
import NumberPicker from 'kumba-components/src/components/NumberPicker';
import ButtonEdit from 'kumba-components/src/components/Button/ButtonEdit';
import Button from 'kumba-components/src/components/Button';
import { mutate } from 'kumba-common/src/libs/apollo';
import { inventoryQuantityUpdate } from '../../graphql-custom/mutations';
import { ERROR } from 'kumba-common/src/constants/colors.constant';

export interface InventoryItem {
  id: string;
  remainingQuantity: number;
  product: {
    titleId: string;
    minInventoryQuantity: number;
  };
}

export function getProductTextByTitleId(titleId) {
  return getNestedText(`products.${titleId}`) || titleId;
}

export function prepareInventoryRows({
  inventoryData,
  onEditRowClicked,
  currEditedProductId,
  onEditQuantityChanged,
  currRemainingQuantity,
  isAdmin,
  onUpdateQuantity,
}: {
  inventoryData: InventoryItem[];
  onEditRowClicked: (params: {
    inventoryProductId: string;
    currQuantity: number;
  }) => void;
  onEditQuantityChanged: (newValue: number) => void;
  currEditedProductId: string;
  currRemainingQuantity: number;
  isAdmin: boolean;
  onUpdateQuantity: () => void;
}) {
  return inventoryData.map(currInventory => {
    return {
      id: currInventory.id,
      color:
        currInventory.remainingQuantity <=
        currInventory.product.minInventoryQuantity
          ? ERROR
          : undefined,
      content: [
        {
          value: getProductTextByTitleId(currInventory.product.titleId),
        },
        {
          value:
            currEditedProductId === currInventory.id ? (
              <div className="edit-quantity-cell">
                <NumberPicker
                  onChange={newValue => onEditQuantityChanged(newValue)}
                  value={currRemainingQuantity}
                />
                <Button onClick={() => onUpdateQuantity()}>
                  {getText('common.buttons.save')}
                </Button>
              </div>
            ) : (
              <React.Fragment>
                {currInventory.remainingQuantity}
                {!!isAdmin && !currEditedProductId && (
                  <ButtonEdit
                    onClick={() =>
                      onEditRowClicked({
                        currQuantity: currInventory.remainingQuantity,
                        inventoryProductId: currInventory.id,
                      })
                    }
                  />
                )}
              </React.Fragment>
            ),
        },
      ],
    };
  });
}

export async function updateInventoryQuantity({
  currEditedProductId,
  currQuantity,
}) {
  await mutate({
    mutation: inventoryQuantityUpdate,
    variables: {
      productInventoryId: currEditedProductId,
      remainingQuantity: currQuantity,
    },
  });
}

export function sortProductInventory(productInventoryA, productInventoryB) {
  return productInventoryA.product.titleId < productInventoryB.product.titleId
    ? -1
    : 1;
}
