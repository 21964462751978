import { getNestedText } from 'kumba-common/src/libs/i18n';
import { getTimezone } from 'kumba-utils/src/date';
import {
  addCurrencyToPrice,
  getCurrencyFromTimezone,
} from 'kumba-common/src/services/localization';
import TimeZones from 'kumba-utils/src/enums/timezones';
import { isRelevantByProductType } from '../helpers';
import {
  Product,
  PetType,
} from 'kumba-serverless/src/generated/dev/prisma-client';
import AppointmentProduct from 'kumba-common/src/interfaces/appointment-product';
import get = require('lodash/get');
import { getProductPrice } from 'kumba-utils/src/screen-helpers/common/pet-services';

export function formatProducts({
  products = [],
  petType,
  selectedProducts,
  productIdsInSubscription,
  isRabiesGiven,
  isNonExpiredSubscription,
}: {
  products: Product[];
  petType: PetType;
  selectedProducts: AppointmentProduct[];
  productIdsInSubscription: string[];
  isRabiesGiven: boolean;
  isNonExpiredSubscription: boolean;
}) {
  const currentTimezone = getTimezone();
  return products
    .map(p => ({
      ...p,
      activityTimezones: get(p, 'activityTimezones.length')
        ? p.activityTimezones
        : [TimeZones.Asia_Jerusalem],
    }))
    .filter(
      product =>
        isRelevantByProductType({
          product,
          petType,
          currentTimezone: currentTimezone as TimeZones,
          isRabiesGiven,
        }) && !productIdsInSubscription.includes(product.id)
    )
    .map(p => {
      const labelTitle = getNestedText(`products.${p.titleId}`) || p.titleId;
      const configuredTimezone: any = getTimezone();
      const priceFormatted = addCurrencyToPrice({
        price: getProductPrice({
          isNonExpiredSubscription,
          originalPrice: p.price,
          productType: p.productType,
        }),
        currency: getCurrencyFromTimezone(configuredTimezone),
      });
      const label = `${labelTitle} - ${priceFormatted}`;
      const selectedProduct = selectedProducts.find(
        sp => sp.productId === p.id
      );
      return {
        label,
        productId: p.id,
        quantity: selectedProduct ? selectedProduct.quantity : 0,
      };
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1));
}

export function formatChangedProducts({
  selectedProducts,
  productId,
  quantity,
}: {
  selectedProducts: AppointmentProduct[];
  productId: string;
  quantity: number;
}) {
  const selectedProduct = selectedProducts.find(
    sp => sp.productId === productId
  );
  if (selectedProduct) {
    if (quantity) {
      return selectedProducts.map(sp => ({
        ...sp,
        quantity: sp.productId === productId ? quantity : sp.quantity,
      }));
    } else {
      return selectedProducts.filter(sp => sp.productId !== productId);
    }
  } else {
    return selectedProducts.concat({ productId, quantity });
  }
}
