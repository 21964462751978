import { formatStringDate } from 'kumba-utils/src/date';
import { SlotRoute } from './interfaces';
import { isFirstTimeVisit } from 'kumba-common/src/components/pet-history/helpers';

// Defaulting to mock routes for type definition
export function formatRouteData({ route }): SlotRoute {
  return {
    id: route.timeSlot.id,
    name: route.timeSlot.name,
    isOpenForScheduling: route.timeSlot.isOpenForScheduling,
    transportationMethod: route.timeSlot.transportationMethod,
    startDate: formatStringDate({
      stringDate: route.timeSlot.startDate,
    }),
    actualStartDate: formatStringDate({
      stringDate: route.timeSlot.actualStartDate,
    }),
    actualEndDate: formatStringDate({
      stringDate: route.timeSlot.actualEndDate,
    }),

    startFromLat: route.timeSlot.startFromLat,
    startFromLng: route.timeSlot.startFromLng,
    destinationLat: route.timeSlot.destinationLat,
    destinationLng: route.timeSlot.destinationLng,
    endDate: formatStringDate({ stringDate: route.timeSlot.endDate }),
    vetName: `${route.timeSlot.vet.user.firstName} ${route.timeSlot.vet.user.lastName}`,
    appointments: route.timeSlot.appointments.map(appointment => ({
      id: appointment.id,
      petId: appointment.pet.id,
      petOwnerId: appointment.pet.petOwner.petOwnerId,
      petName: appointment.pet.name,
      petType: appointment.pet.petType,
      profilePictureUrl: appointment.pet.profilePictureUrl,
      plannedStartDate: appointment.plannedStartDate,
      actualStartDate: appointment.actualStartDate,
      actualEndDate: appointment.actualEndDate,
      addressLat: appointment.pet.petOwner.user.addressLat,
      addressLng: appointment.pet.petOwner.user.addressLng,
      address: appointment.pet.petOwner.user.address,
      petOwnerName: `${appointment.pet.petOwner.user.firstName} ${appointment.pet.petOwner.user.lastName}`,
      petOwnerPhone: appointment.pet.petOwner.user.phone,
      isFirstTimeVisit: isFirstTimeVisit({
        petServices: appointment.pet.petServices,
      }),
    })),
  };
}
