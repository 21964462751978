import {
  InputTypes,
  InputDefinition,
} from 'kumba-components/src/components/Form';
import { getText } from 'kumba-common/src/libs/i18n';

export const petsFilterInputDefinition: InputDefinition[] = [
  {
    name: 'petName',
    inputType: InputTypes.Text,
    isRequired: false,
    label: getText('petName'),
  },
  {
    name: 'ownerFirstName',
    inputType: InputTypes.Text,
    isRequired: false,
    label: getText('medicalConsultations.ownerFirstName'),
  },
  {
    name: 'ownerLastName',
    inputType: InputTypes.Text,
    isRequired: false,
    label: getText('medicalConsultations.ownerLastName'),
  },
];
