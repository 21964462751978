import styled from 'styled-components';

const signupStyledComponent = styled.div`
  @media all and (min-width: 480px) {
    form {
      margin: 0 auto;
    }
  }

  form .help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
`;

export default signupStyledComponent;
