import styled from 'styled-components';
import { ErrorColor } from 'kumba-components/src/constants/colors';

const appStyledComponent = styled.div`
  direction: ${({ direction }) => direction || 'rtl'};
  .button__delete button {
    color: ${ErrorColor};
  }
`;

export default appStyledComponent;
