import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { showMessage } from '../../actions/app-actions';
import { getText } from 'kumba-common/src/libs/i18n';
import FormModal from 'kumba-components/src/components/FormModal';
import { InputTypes } from 'kumba-components/src/components/Form';
import {
  getStringDateFromTimestamp,
  DAY_IN_TIMESTAMP,
  formatStringDate,
} from 'kumba-utils/src/date';
import { upsertMedicalConsultation } from './helpers';
import { ToastTypes } from 'kumba-components/src/components/Toast';
import initialState from '../../reducers/initial-state';

interface UpsertConsultationProps {
  onClose: ({ isPostUpdate }: { isPostUpdate: boolean }) => void;
  showMessageTrigger: any;
  petId: string;
  vetId: string;
  currConsulation?: {
    consultationId: string;
    consultationDate: string;
    summary: string;
    documentGroupUrl?: string;
  };
}

function UpsertConsultation({
  onClose,
  showMessageTrigger,
  petId,
  vetId,
  currConsulation,
}: UpsertConsultationProps) {
  const formTitle =
    currConsulation && currConsulation.consultationId
      ? getText('medicalConsultations.addMedicalConsultation')
      : getText('medicalConsultations.editMedicalConsultation');
  return (
    <FormModal
      formTitle={formTitle}
      isOpen={true}
      inputDefinitions={[
        {
          name: 'consultationDate',
          isRequired: true,
          label: getText('date'),
          initialValue:
            currConsulation && currConsulation.consultationDate
              ? formatStringDate({
                  stringDate: currConsulation.consultationDate,
                  format: 'YYYY-MM-DDThh:mm',
                })
              : getStringDateFromTimestamp(Date.now() + 2 * DAY_IN_TIMESTAMP, {
                  format: 'YYYY-MM-DDTHH:mm',
                }),
          inputType: InputTypes.Date,
        },
        {
          name: 'summary',
          isRequired: true,
          label: getText('medicalConsultations.summary'),
          inputType: InputTypes.MarkdownEditor,
          initialValue: currConsulation ? currConsulation.summary : undefined,
        },
        {
          inputType: InputTypes.File,
          label: getText('medicalDocuments'),
          helperText: getText('medicalConsultations.medicalDocumentsHelper'),
          name: 'documentGroupUrl',
          isRequired: false,
          isDocumentUploader: true,
          initialValue: currConsulation
            ? currConsulation.documentGroupUrl
            : undefined,
        },
      ]}
      onSubmit={async ({ summary, consultationDate, documentGroupUrl }) => {
        try {
          await upsertMedicalConsultation({
            summary,
            consultationDate,
            petId,
            documentGroupUrl: documentGroupUrl || undefined,
            consultationId: currConsulation && currConsulation.consultationId,
            vetId,
          });
          showMessageTrigger({
            message: getText('medicalConsultation.addSuccess'),
          });
          onClose({ isPostUpdate: true });
        } catch (error) {
          console.error(error);
          showMessageTrigger({
            messageType: ToastTypes.Error,
            message: 'Something went wrong',
          });
          onClose({ isPostUpdate: false });
        }
      }}
      onClose={() => onClose({ isPostUpdate: false })}
    />
  );
}

const mapStateToProps = (state = initialState) => {
  return {
    vetId: state.auth.userId,
  };
};
export default compose(
  connect(
    mapStateToProps,
    { showMessageTrigger: showMessage }
  )
)(UpsertConsultation);
