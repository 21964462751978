import * as React from 'react';
import styled from 'styled-components';
import RedoIcon from '@material-ui/icons/Redo';

import AppointmentPetAccordion from 'kumba-common/src/components/appointment-pet-accordion';
import Badge from 'kumba-components/src/components/Badge';
import { isLaterThan } from 'kumba-utils/src/date';
import IconButton from '@material-ui/core/IconButton';

interface RouteAccordionViewProps {
  appointments: { id }[];
  slotId: string;
  onPetClicked: ({ slotId, appointmentId }) => void;
  isAdmin?: boolean;
  onEditAppointment?: (ITimelineEvent) => void;
}

const RouteAccordionViewStyledComponent = styled.div`
  max-width: 100vw;
  .appointment-row {
    display: flex;
    align-items: start;
    button.go-to-appointment {
      min-width: 6vw;
      padding: 0;
      margin-top: 3rem;
      & * {
        justify-content: flex-start;
      }
    }
    .accordion {
      min-width: 50vw;
    }
  }
`;

export function sortAppointments(appointmentA, appointmentB) {
  return isLaterThan(
    appointmentA.plannedStartDate,
    appointmentB.plannedStartDate
  )
    ? 1
    : -1;
}

function RouteAccordionView({
  appointments = [],
  slotId,
  onPetClicked,
  isAdmin,
  onEditAppointment,
}: RouteAccordionViewProps) {
  return (
    <RouteAccordionViewStyledComponent>
      {appointments.sort(sortAppointments).map((appointment, index) => (
        <div key={appointment.id} className="appointment-row">
          <Badge badgeContent={index + 1}>
            <AppointmentPetAccordion
              appointment={appointment}
              canEdit={isAdmin}
              isVet={true}
              onEditAppointment={onEditAppointment}
            />
          </Badge>
          <IconButton
            onClick={() =>
              onPetClicked({ slotId, appointmentId: appointment.id })
            }
            className="go-to-appointment"
            aria-label="Go to appointment"
          >
            <RedoIcon />
          </IconButton>
        </div>
      ))}
    </RouteAccordionViewStyledComponent>
  );
}

export default RouteAccordionView;
